import React from 'react'
import { graphql } from 'gatsby'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { Header } from '@/components/Header'
import { SectionProps } from '@/containers/SectionFactory'

interface NavSectionProps extends SectionProps {
  menuList?: Array<Object>
  menuListStateToggle?: Function
}

export default class NavSection extends React.Component<NavSectionProps, any> {
  state = {
    isOpenMenuList: false,
    isopenmenulistitem: false,
    isDesktopView: false,
    DesktopMenuWidth: 20,
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      this.setState({
        DesktopMenuWidth: window.innerWidth,
        isDesktopView: window.innerWidth > 1100,
      })
      window.addEventListener('resize', () => {
        this.setState({
          isDesktopView: window.innerWidth > 1100,
          DesktopMenuWidth: window.innerWidth,
        })
      })
    }
  }

  _toggleMenuList = () => {
    const { isOpenMenuList } = this.state
    const { menuListStateToggle } = this.props

    if (menuListStateToggle) menuListStateToggle(!isOpenMenuList)

    if (isOpenMenuList) {
      enableBodyScroll()
      this.setState({
        isopenmenulistitem: !isOpenMenuList,
      })

      setTimeout(() => {
        this.setState({
          isOpenMenuList: !isOpenMenuList,
        })
      }, 1000)
    } else {
      disableBodyScroll()
      this.setState({
        isOpenMenuList: !isOpenMenuList,
        isopenmenulistitem: !isOpenMenuList,
      })
    }
  }

  _filterMenuList = (filter: any) => {
    let menuList = this.props.menuList
    menuList = menuList && menuList.filter((item: any) => !item.shouldShowOnMobile)
    if (filter === 1) {
      const list = []
      menuList &&
        menuList.map((item: any) => {
          if (item.siteRelated) {
            if (item.flowerDrewSocialMedia.length === 0) {
              list.push(item)
            } else {
              item.flowerDrewSocialMedia[0].titles.map((socialItem: any) => {
                socialItem.isSocialMediaLink = true
                socialItem.backgroundColor = socialItem.desktopBackgroundColor
                socialItem.headingLevel = 3
                socialItem.title = socialItem.ctaText
                socialItem.url = socialItem.ctaUrl
                socialItem.siteRelated = true
                socialItem.textColor = socialItem.desktopTextColor
                list.push(socialItem)
              })
            }
          }
        })
      return list
    } else {
      const list = []
      menuList &&
        menuList.map((item: any) => {
          if (!item.siteRelated) {
            if (item.flowerDrewSocialMedia.length === 0) {
              list.push(item)
            } else {
              const shadowColor = item.flowerDrewSocialMedia[0].textShadowColor
              item.flowerDrewSocialMedia[0].titles.map((socialItem: any) => {
                socialItem.isSocialMediaLink = true
                socialItem.backgroundColor = socialItem.desktopBackgroundColor
                socialItem.headingLevel = 3
                socialItem.title = socialItem.ctaText
                socialItem.url = socialItem.ctaUrl
                socialItem.siteRelated = false
                socialItem.textColor = socialItem.desktopTextColor
                socialItem.textShadowColor = shadowColor

                list.push(socialItem)
              })
            }
          }
        })
      return list
    }
  }

  _mergeMenuList = (DesktopSiteReltatedMenuList: any, DesktopexternalMenuList: any) => {
    const array1 = DesktopSiteReltatedMenuList
    const array2 = DesktopexternalMenuList
    let array3 = []
    if (array1 && array2) {
      const length = array1.length > array2.length ? array1 : array2
      length.map((data: any, index: any) => {
        if (array1[index]) array3.push(array1[index])
        if (array2[index]) array3.push(array2[index])
      })

      return array3
    } else {
      return []
    }
  }

  //get current browser scroll bar width so the side(Menu/Home) panels width doesn't interfere with main content
  _getScrollBarWidth = () => {
    if (typeof document !== `undefined`) {
      var inner = document.createElement('p')
      inner.style.width = '100%'
      inner.style.height = '200px'

      var outer = document.createElement('div')
      outer.style.position = 'absolute'
      outer.style.top = '0px'
      outer.style.left = '0px'
      outer.style.visibility = 'hidden'
      outer.style.width = '200px'
      outer.style.height = '150px'
      outer.style.overflow = 'hidden'
      outer.appendChild(inner)

      document.body.appendChild(outer)
      var w1 = inner.offsetWidth
      outer.style.overflow = 'scroll'
      var w2 = inner.offsetWidth
      if (w1 == w2) w2 = outer.clientWidth

      document.body.removeChild(outer)

      return w1 - w2
    }
  }

  render() {
    const { isOpenMenuList, isopenmenulistitem, isDesktopView, DesktopMenuWidth } = this.state

    const DesktopSiteReltatedMenuList = this._filterMenuList(1)
    const DesktopexternalMenuList = this._filterMenuList(2)
    const menuItemsList = this._mergeMenuList(DesktopSiteReltatedMenuList, DesktopexternalMenuList)
    const ScrollBarWidth = this._getScrollBarWidth()

    return (
      <Header
        data={this.props}
        isOpenMenuList={isOpenMenuList}
        toggleMenuList={this._toggleMenuList}
        menuListStateToggle={this.props}
        isopenmenulistitem={isopenmenulistitem}
        DesktopSiteReltatedMenuList={DesktopSiteReltatedMenuList}
        DesktopexternalMenuList={DesktopexternalMenuList}
        menuItemsList={menuItemsList}
        ScrollBarWidth={ScrollBarWidth}
        isDesktopView={isDesktopView}
        DesktopMenuWidth={DesktopMenuWidth}
      />
    )
  }
}

export const type = 'DatoCmsNavSection'
export const query = graphql`
  fragment NavSection on DatoCmsNavSection {
    menuBrandLogo {
      url
    }
    closeIcon {
      url
    }
    dotIcon {
      url
    }
    homeButtonColors {
      id
      colorItem {
        hex
      }
    }
    homeButtonTextColor {
      hex
    }
    desktopMenuButtonColor {
      id
      menuColorItem {
        hex
      }
    }
    menuButtonTextColor {
      hex
    }
    homeTopBottomTileColor {
      topTile {
        hex
      }
      bottomTile {
        hex
      }
    }

    menuTopBottomTileColor {
      topTile {
        hex
      }
      bottomTile {
        hex
      }
    }
    menuList {
      id
      title
      url
      siteRelated
      flowerDrewSocialMedia {
        ... on DatoCmsThreeColumnsLinkSection {
          ...ThreeColumnsLinkSection
        }
      }
      multiColoredTextValue {
        hex
      }
      backgroundColor {
        hex
      }
      textColor {
        hex
      }
      shouldShowOnDesktop
      shouldShowOnMobile
      headingLevel
    }
  }
`
