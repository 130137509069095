import React from 'react'
import { graphql } from 'gatsby'
import FullBleedImageSection from '@/components/FullBleedImageSection'

const FullBleedSectionContainer: React.FunctionComponent<any> = props => {
  return (
    <FullBleedImageSection {...props} />
  )
}

export default FullBleedSectionContainer

export const type = 'DatoCmsFullBleedImageSection'
export const query = graphql`
  fragment FullBleedImageSection on DatoCmsFullBleedImageSection {
    id
    titleText
    titleTextUrl
    titleType
    titleColor {
      hex
    }
    boxColor {
      hex
    }
    boxShadowColor {
      hex
    }
    borderColor {
      hex
    }
    imageAsset {
      xs: fluid(imgixParams: { auto: "format" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
      sm: fluid(imgixParams: { auto: "format" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }
    pageNotFound
    shouldBeFullScreenImage
    notFoundNumberColor {
      firstColor {
        hex
      }
      secondColor {
        hex
      }
      thirdColor {
        hex
      }
    }
  }
`