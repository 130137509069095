import React from 'react'
import { graphql } from 'gatsby'

import { SectionProps } from '@/containers/SectionFactory'
import { TwoColumnImageContent } from '@/components/TwoColumnsImageContentSectionComponent'

interface TwoColumnsImageContentSectionProps extends SectionProps {
  tiles?: Array<Object>
}

const TwoColumnsImageContentSection: React.SFC<TwoColumnsImageContentSectionProps> = props => {
  return (
    <>
      <TwoColumnImageContent {...props} />
    </>
  )
}

export default TwoColumnsImageContentSection

export const type = 'DatoCmsTwoColumnsImageContentSection'
export const query = graphql`
  fragment TwoColumnsImageContentSection on DatoCmsTwoColumnsImageContentSection {
    id
    tiles {
      ... on DatoCmsContentTile {
        backgroundColor { hex }
        title
        titleType
        titleColor  { hex }
        copyText
        copyColor  { hex }
        cta
        ctaUrl
        isAltFontSize
        isAltImageRatio
        ctaBackgroundColor {
          hex
        }
        ctaShadowColor {
          hex
        }
        ctaTextColor {
          hex
        }
      }
      ... on DatoCmsImageTile {
        imageAsset {
          xs: fluid(imgixParams: { auto: "format,compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
          sm: fluid(imgixParams: { auto: "format,compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`
