import React from 'react'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'

import Link from '@/components/Link'
import ResponsiveImage, { BreakpointKeyedObject } from '@/components/ResponsiveImage'
import theme from '@/style/theme'
import { Heading, Text } from '@/components/text'
import { Box } from '@/components/layout'

interface FullBleedImageSectionProps {
  titleText?: string
  titleTextUrl?: String
  titleColor?: any
  titleType?: string
  borderColor?: any
  boxColor?: any
  boxShadowColor?: any
  imageAsset: BreakpointKeyedObject
  playUrl: String
  playIcon: BreakpointKeyedObject
  pageNotFound: Boolean
  notFoundNumberColor: Array<Object>
  playVideo: Object
  shouldBeFullScreenImage: Boolean
}

const BorderBox = styled(Box)<any>`
  box-sizing: border-box;
  border: ${props => (props.borderColor ? `1em solid ${props.borderColor}` : 'none')};
`

const ShadowBox = styled(Box)<any>`
  width: 60%;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  background-color: ${props =>
    props.boxBackgroundColor ? props.boxBackgroundColor : 'transparent'};
  box-shadow: ${props => (props.boxShadowColor ? `0.6rem 0.6rem ${props.boxShadowColor}` : 'none')};
`

const Title = styled(Heading)<any>`
  margin: ${props => (props.m ? props.m : 'unset')};
  padding: ${props => `${props.pt ? props.pt / 16 : 0}rem ${props.px ? props.px / 16 : 0}rem 0`};
  text-align: center;
  line-height: unset;
`

const NotFoundText = styled(Text)<any>`
  position: absolute;
  top: ${props => (props.top ? `${props.top / 16}rem` : 'unset')};
  left: ${props => (props.left ? `${props.left / 16}rem` : 'unset')};
  font-family: ${theme.fontFaces.headings};
  transform: translate(-50%, -50%);
  font-size: 10rem;
`
const FirstNumberAnimation = keyframes`
  0% {
    top: 60%;
    left: 30%;
  }

  25% {
    top: 40%;
    left: 25%;
  }

  50% {
    top: 20%;
    left: 20%;
  }

  75% {
    top: 80%;
    left: 80%;
  }

  100% {
    top: 60%;
    left: 30%;
  }
`

const SecondNumberAnimation = keyframes`
  0% {
    top: 10%;
    left: 80%;
  }

  25% {
    top: 50%;
    left: 60%;
  }


  50% {
    top: 80%;
    left: 40%;
  }

  75% {
    top: 30%;
    left: 20%;
  }

  100% {
    top: 10%;
    left: 80%;
  }
`

const ThirdNumberAnimation = keyframes`
  0% {
    top: 90%;
    left: 70%;
  }

  25% {
    top: 40%;
    left: 70%;
  }

  50% {
    top: 10%;
    left: 70%;
  }

  75% {
    top: 90%;
    left: 70%;
  }

  100% {
    top: 90%;
    left: 70%;
  }
`

const NotFoundText1 = styled(NotFoundText)<any>`
  animation: ${FirstNumberAnimation} 5s infinite;
`

const NotFoundText2 = styled(NotFoundText)<any>`
  animation: ${SecondNumberAnimation} 5s infinite;
`

const NotFoundText3 = styled(NotFoundText)<any>`
  animation: ${ThirdNumberAnimation} 5s infinite;
`

const FullBleedImageSection: React.FunctionComponent<FullBleedImageSectionProps & any> = props => {
  const {
    titleText,
    titleColor,
    titleTextUrl,
    titleType,
    boxColor,
    boxShadowColor,
    imageAsset,
    playIcon,
    playUrl,
    playInNewTab,
    borderColor,
    playVideo,
    pageNotFound,
    notFoundNumberColor,
    shouldBeFullScreenImage,
    ...rest
  } = props

  const titleLevel = titleType ? parseInt(titleType.replace(/\D/g, '')) : 0
  return (
    <>
      <Box
        position={'relative'}
        ref={a => {
          if (a) {
            let shadoBoxHeight =
              a.querySelector('.shadow-box') && a.querySelector('.shadow-box').clientHeight
            let borderBoxHeight =
              a.querySelector('.border-box') && a.querySelector('.border-box').clientHeight

            if (shadoBoxHeight + 20 > borderBoxHeight) {
              a.querySelector('.gatsby-image-wrapper').style.height = `${shadoBoxHeight + 50}px`
            }
          }
        }}
      >
        <BorderBox
          width={1}
          borderColor={borderColor ? borderColor.hex : false}
          className="border-box"
        >
          {titleText.length > 0 && (
            <ShadowBox
              boxBackgroundColor={boxColor ? boxColor.hex : false}
              boxShadowColor={boxShadowColor ? boxShadowColor.hex : false}
              className="shadow-box"
            >
              {titleTextUrl ? (
                <Link to={titleTextUrl}>
                  <Title
                    m={0}
                    pt={15}
                    px={25}
                    fontSize={[3, 4, 5]}
                    color={titleColor}
                    level={titleLevel}
                  >
                    {titleText}
                  </Title>
                </Link>
              ) : (
                <Title
                  m={0}
                  pt={15}
                  px={25}
                  fontSize={[3, 4, 5]}
                  color={titleColor}
                  level={titleLevel}
                >
                  {titleText}
                </Title>
              )}
            </ShadowBox>
          )}
          <Box
            ref={a => {
              if (a && shouldBeFullScreenImage) {
                //default page load
                a.querySelector('.gatsby-image-wrapper').style.height = '100vh'

                //on resize screen
                if (typeof window !== `undefined`) {
                  window.onresize = () => {
                    a.querySelector('.gatsby-image-wrapper').style.height = '100vh'
                  }
                }
              }
            }}
          >
            <ResponsiveImage fluid={imageAsset} fullBleed={true} {...rest} />
          </Box>
          {pageNotFound ? (
            <Box>
              <NotFoundText1 color={notFoundNumberColor && notFoundNumberColor[0].firstColor.hex}>
                4
              </NotFoundText1>
              <NotFoundText2 color={notFoundNumberColor && notFoundNumberColor[0].secondColor.hex}>
                0
              </NotFoundText2>
              <NotFoundText3 color={notFoundNumberColor && notFoundNumberColor[0].thirdColor.hex}>
                4
              </NotFoundText3>
            </Box>
          ) : null}
        </BorderBox>
      </Box>
    </>
  )
}

export default FullBleedImageSection
