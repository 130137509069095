import React, { ComponentType, FunctionComponent } from 'react'

import SingleColumnLeftAlignedWithButtonSection, {
  type as SingleColumnLeftAlignedWithButtonSectionType,
} from '@/containers/SingleColumnLeftAlignedWithButtonSection'
import FullBleedImageSection, {
  type as FullBleedImageSectionType,
} from '@/containers/FullBleedImageSectionContainer'
import TwoColumnsImageContentSection, {
  type as TwoColumnsImageContentSectionType,
} from '@/containers/TwoColumnsImageContentSection'
import ScrollingTextSection, {
  type as ScrollingTextSectionType,
} from '@/containers/ScrollingTextSection'

import ThreeColumnsLinkSectionContainer, {
  type as ThreeColumnsLinkSectionType,
} from '@/containers/ThreeColumnsLinkSectionContainer'

import ThreeColumnsImageLinksSection, {
  type as ThreeColumnsImageLinksSectionType,
} from '@/containers/ThreeColumnsImageLinksSection'
import SingleColumnCenterAlignedSection, {
  type as SingleColumnCenterAlignedType,
} from '@/containers/OneColumnCenterAlignedContainer'

import VideoPlayer, { type as VideoPlayerType } from '@/containers/VideoPlayerContainer'
import Article, { type as ArticleType } from '@/containers/ArticleSectionContainer'

import HomeHeroSection, { type as HomeHeroSectionType } from '@/containers/HomeHeroSection'
import SingleColumnLeftAlignedContainer, {
  type as SingleColumnLeftAlignedType,
} from '@/containers/SingleColumnLeftAlignedContainer'

import NewsLetterSection, { type as NewsLetterSectionType } from '@/containers/NewsLetterSection'

import FullBleedImageShadowBoxSection, {
  type as FullBleedImageShadowBoxSectionType,
} from '@/containers/FullBleedImageShadowBoxSection'

import { ErrorText } from '@/components/text'

export interface SectionProps {
  __typename: string
  key: number
}

export const sectionComponent = (props: SectionProps) => {
  const typename = props.__typename
  let Component: ComponentType<SectionProps & any> | null = null
  if (typename === FullBleedImageSectionType) {
    Component = FullBleedImageSection
  } else if (typename === TwoColumnsImageContentSectionType) {
    Component = TwoColumnsImageContentSection
  } else if (typename === ScrollingTextSectionType) {
    Component = ScrollingTextSection
  } else if (typename === ThreeColumnsLinkSectionType) {
    Component = ThreeColumnsLinkSectionContainer
  } else if (typename === SingleColumnLeftAlignedWithButtonSectionType) {
    Component = SingleColumnLeftAlignedWithButtonSection
  } else if (typename === ThreeColumnsImageLinksSectionType) {
    Component = ThreeColumnsImageLinksSection
  } else if (typename === SingleColumnCenterAlignedType) {
    Component = SingleColumnCenterAlignedSection
  } else if (typename === HomeHeroSectionType) {
    Component = HomeHeroSection
  } else if (typename === SingleColumnLeftAlignedType) {
    Component = SingleColumnLeftAlignedContainer
  } else if (typename === NewsLetterSectionType) {
    Component = NewsLetterSection
  } else if (typename === FullBleedImageShadowBoxSectionType) {
    Component = FullBleedImageShadowBoxSection
  } else if (typename === VideoPlayerType) {
    Component = VideoPlayer
  } else if (typename === ArticleType) {
    Component = Article
  }

  return Component
}

const SectionFactory: React.SFC<SectionProps> = (props: SectionProps) => {
  const SectionComponent = sectionComponent(props) as FunctionComponent<SectionProps>
  if (SectionComponent) {
    return <SectionComponent {...props as any} />
  }

  return <ErrorText as={'div'}>Module {props.__typename} not found</ErrorText>
}

export default SectionFactory
