import React from 'react'
import styled from '@emotion/styled'

import theme from '@/style/theme'
import { SectionProps } from '@/containers/SectionFactory'
import { Box, Flex } from '@/components/layout'
import { Text, Heading } from '@/components/text'
import Link from '@/components/Link'

interface SingleColumnLeftAlignSectionProps extends SectionProps {
  backgroundColor?: Object
  subtitleText?: String
  subtitleType?: String
  subtitleColor?: Object
  titleText?: String
  titleType?: String
  titleColor?: Object
  copyText?: String
  copyColor?: Object
  copyType?: String
  numberDecorator?: Number
  numberDecoratorColor?: Object
  linkUrl?: String
  linkTitle?: String
  linkColor?: Object
  linkOpenInNewTab?: Boolean
  subLinkTitle?: String
  subLinkUrl?: String
  subLinkColor?: Object
  subLinkOpenInNewTab?: Boolean
  bottomText?: String
  bottomColor?: Object
  bottomLinkTitle?: String
  bottomLinkColor?: Object
  bottomLinkUrl?: String
  bottomLinkOpenInNewTab?: boolean
}

const StyledLink = styled(Link)<any>`
  display: block;
  color: ${props => (props.color ? props.color : '#000')};
  text-decoration: underline;
`

const BottomLink = styled(StyledLink)`
  display: inline-block;
`

const Container = styled(Box)<any>`
  box-sizing: border-box;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
`

const StyledText = styled(Text)`
  text-decoration: underline;
`

const HeadingText = styled(Heading)<any>`
  padding: ${props =>
    `${props.pt ? props.pt / 16 : 0}rem ${props.pr ? props.pr / 16 : 0}rem ${
      props.pb ? props.pb / 16 : 0
    }rem ${props.pl ? props.pl / 16 : 0}rem`};
  margin: ${props => (props.m ? props.m : 'unset')};
`

const NumberText = styled(Text)<any>`
  display: inline-block;
  border: 0.16rem solid ${props => (props.border ? props.border : '#000')};
  border-radius: 50%;
  width: 3rem;
  text-align: center;
  padding: ${props => (props.py ? `${props.py / 16}rem` : 0)}
    ${props => (props.px ? `${props.px / 16}rem` : 0)};
`

const SingleColumnLeftAlignSection: React.FunctionComponent<
  SingleColumnLeftAlignSectionProps & any
> = props => {
  const {
    backgroundColor,
    subtitleText,
    subtitleType,
    subtitleColor,
    titleText,
    titleType,
    headingLevel,
    titleColor,
    copyText,
    formatCopyText,
    copyColor,
    copyType,
    numberDecorator,
    numberDecoratorColor,
    linkUrl,
    linkTitle,
    linkColor,
    linkOpenInNewTab,
    subLinkTitle,
    subLinkUrl,
    subLinkColor,
    subLinkOpenInNewTab,
    bottomText,
    bottomTextColor,
    bottomLinkTitle,
    bottomLinkColor,
    bottomLinkUrl,
    bottomLinkOpenInNewTab,
  } = props

  return (
    <>
      <Container
        position={'relative'}
        px={25}
        pt={20}
        pb={15}
        width={1}
        backgroundColor={backgroundColor && backgroundColor.hex}
      >
        {subtitleType === 'breadcrumbs' ? (
          <Box>
            <Text
              fontSize={8}
              fontFamily={theme.fontFaces.link}
              color={subtitleColor && subtitleColor.hex}
            >
              {subtitleText}
            </Text>
          </Box>
        ) : null}

        {(numberDecorator || linkTitle) && (
          <Flex pb={20} position={'relative'}>
            {numberDecorator && (
              <Box pr={20}>
                <NumberText
                  py={12}
                  fontFamily={theme.fontFaces.link}
                  fontSize={7}
                  border={numberDecoratorColor && numberDecoratorColor.hex}
                  color={numberDecoratorColor ? numberDecoratorColor.hex : '#000'}
                >
                  {numberDecorator}
                </NumberText>
              </Box>
            )}
            {linkTitle && (
              <Box left={numberDecorator ? 60 : 'unset'}>
                <StyledLink
                  color={linkColor && linkColor.hex}
                  to={linkUrl}
                  target={linkOpenInNewTab ? '_blank' : '_self'}
                >
                  <StyledText fontSize={7} fontFamily={theme.fontFaces.body}>
                    {linkTitle}
                  </StyledText>
                </StyledLink>

                <StyledLink
                  color={subLinkColor && subLinkColor.hex}
                  title={subLinkTitle}
                  to={subLinkUrl}
                  target={subLinkOpenInNewTab ? '_blank' : '_self'}
                >
                  <StyledText fontSize={7} fontFamily={theme.fontFaces.link}>
                    {subLinkTitle}
                  </StyledText>
                </StyledLink>
              </Box>
            )}
          </Flex>
        )}

        {titleText && titleType && !titleType.includes('heading') ? (
          <Box pt={15}>
            <Text
              fontFamily={
                titleType === 'largeBody' ? theme.fontFaces.link : theme.fontFaces[titleType]
              }
              color={titleColor && titleColor.hex}
              fontSize={titleType === 'largeBody' ? 10 : 7}
            >
              {titleText}
            </Text>
          </Box>
        ) : titleText ? (
          <Box pt={15}>
            <HeadingText
              fontFamily={theme.fontFaces.headings}
              m={0}
              pr={35}
              color={titleColor && titleColor.hex}
              level={headingLevel}
            >
              {titleText}
            </HeadingText>
          </Box>
        ) : null}

        {subtitleType !== 'breadcrumbs' && subtitleText ? (
          <Box pt={10} pb={10}>
            <Text
              color={subtitleColor && subtitleColor.hex}
              fontSize={[6, 7, subtitleType === 'largeBody' ? 10 : 7]}
              fontFamily={
                subtitleType.includes('heading')
                  ? theme.fontFaces.headings
                  : subtitleType === 'largeBody'
                  ? theme.fontFaces.link
                  : theme.fontFaces.body
              }
            >
              {subtitleText}
            </Text>
          </Box>
        ) : null}

        {copyText && (
          <Box pt={15} width={1} m={0}>
            <Text
              color={copyColor && copyColor.hex}
              fontSize={[9, 8, copyType === 'smallBody' ? 8 : 6]}
              fontFamily={copyType === 'smallBody' ? theme.fontFaces.link : theme.fontFaces.body}
              dangerouslySetInnerHTML={formatCopyText}
            />
          </Box>
        )}

        {(bottomText || bottomLinkTitle) && (
          <Box pt={20} pb={15}>
            {bottomText && (
              <Text color={bottomTextColor && bottomLinkColor.hex} fontSize={8}>
                {bottomText}
              </Text>
            )}
            {bottomLinkTitle && (
              <Box>
                <BottomLink
                  color={bottomLinkColor && bottomLinkColor.hex}
                  title={bottomLinkTitle}
                  to={bottomLinkUrl}
                  target={bottomLinkOpenInNewTab ? '_blank' : '_self'}
                >
                  <Text fontSize={8} fontFamily={theme.fontFaces.link}>
                    {bottomLinkTitle}
                  </Text>
                </BottomLink>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </>
  )
}

export default SingleColumnLeftAlignSection
