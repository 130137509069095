import React from 'react'
import { graphql } from 'gatsby'

import { SectionProps } from '@/containers/SectionFactory'
import Footer from '@/components/FooterComponent'

interface FooterSectionProps extends SectionProps {
  copyRightsBackgroundColor?: Object
  copyRightsTextColor?: Object
  copyRightsText?: String
  footerMenuList?: Array<String>
}

const FooterSection: React.FunctionComponent<FooterSectionProps> = props => {
  return (
    <Footer {...props}/>
  )
}

export default FooterSection

export const type = 'DatoCmsFooter'
export const query = graphql`
  fragment Footer on DatoCmsFooter {
    footerMenuList {
      id
      title
      titleTextColor {
        hex
      }

      backgroundColor {
        hex
      }
      
      footerSubMenu {
        id
        subMenuList {
          id
          ctaText
          textColor {
            hex
          }
          ctaUrl
          ctaShouldOpenInNewWindow
        }
      }
    }
    
    copyRightsText
    copyRightsTextColor {
      hex
    }
    copyRightsBackgroundColor {
      hex
    }
  }
`