import React from 'react'
import ReactModal from 'react-modal'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player'

import { Box } from '@/components/layout'
import { RoundedButtonContainer } from '@/components/Button'

const StyledModal = styled(ReactModal)`
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  outline: none;
  border-color: transparent;
`
const CustomRoundedBtn = styled(RoundedButtonContainer)`
  color: #f49682;
  background-color: #f6c818;
  border: transparent;
`

const OverlayStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 999
}

export const Modal: React.FunctionComponent<any> = props => {
  return (
    <StyledModal style={{ overlay: OverlayStyle }} {...props}>
      {props.children}
    </StyledModal>
  )
}

export const VideoModal: React.FunctionComponent<any> = props => {
  const { onRequestClose, closeModal, videoUrl, controls, ...rest } = props
  return (
    <Modal ariaHideApp={false} onRequestClose={onRequestClose} {...rest} shouldCloseOnEsc={true}>
      <CloseButtonContainer>
        <CustomRoundedBtn 
          px={'0.5rem'} 
          py={'0.22rem'} 
          onClick={onRequestClose}
        >
        &times;
        </CustomRoundedBtn>
      </CloseButtonContainer>
      <ReactPlayer 
        className="react-player" 
        url={videoUrl} 
        width="80vw" 
        height="46vw" 
        style={{
          maxHeight: '90vh'
          }}
        playing
        controls={controls}
        />
    </Modal>
  )
}

const CloseButtonContainer = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
`