import React from 'react'
import styled from '@emotion/styled'

import theme from '@/style/theme'
import Link from '@/components/Link'

import { Box } from '@/components/layout'
import { Heading, Text } from '@/components/text'

interface SingleColumnCenterAlignedProps {
  backgroundColor?: Object
  id?: String
  subtitleText?: String
  titleText?: String
  linkText?: String
  linkUrl?: String
  titleUrlOpenInNewTab: Boolean
  titleTextUrl?: String
  drewPickIconStyle?: number
  drewPickIconText?: String
  linkShouldOpenInNewWindow?: boolean
  shouldShowDrewPickIcon?: boolean
}

const LinkCTA = styled(Link)`
  color: #000;
  font-family: ${theme.fontFaces.body};
`

const LinkBox = styled(Box)`
  text-align: center;
`

const LinkText = styled(Text)`
  color: #000;
  text-decoration: underline;
`

const Title = styled(Heading)<any>`
  margin: ${props => (props.mt ? `${props.mt / 16}rem` : '0')} 0 0;
  margin-bottom: ${props => (props.linkText ? 20 / 16 : 0)}rem;
  color: #000;
  font-family: ${theme.fontFaces.headings};
`

const SubTitle = styled(Text)`
  display: block;
  color: #000;
  font-family: ${theme.fontFaces.link};
`

const BlockContainer = styled(Box)<any>`
  box-sizing: border-box;
  padding: ${props => (props.py ? `${props.py / 16}rem` : '0')} 0;
  margin: 0 auto;
  position: relative;
  background-color: ${props => props.backgroundColor};
  text-align: center;
`

const BoxIcon = styled(Box)<any>`
  box-sizing: border-box;
  width: ${props => (props.width ? `${props.width / 16}rem` : '0')};
  height: ${props => (props.height ? `${props.height / 16}rem` : '0')};
  padding: ${props => (props.py ? `${props.py / 16}rem` : '0')}
    ${props => (props.px ? `${props.px / 16}rem` : '0')};
  border: 0.714rem solid ${props => (props.drewPickIconStyle == 1 ? '#F7C918' : '#f86950')};
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  right: 1rem;
  bottom: -2.357rem;
  background-color: ${props => (props.drewPickIconStyle == 1 ? '#FAA90F' : '#f7879b')};
  font-family: ${theme.fontFaces.link};
  font-size: 0.41667rem;
`

const SingleColumnCenterAligned: React.FunctionComponent<
  SingleColumnCenterAlignedProps & any
> = props => {
  const {
    backgroundColor,
    subtitleText,
    titleText,
    titleTextUrl,
    linkText,
    linkUrl,
    titleUrlOpenInNewTab,
    drewPickIconStyle,
    linkShouldOpenInNewWindow,
    shouldShowDrewPickIcon,
    drewPickIconText,
  } = props
  return (
    <>
      <BlockContainer
        backgroundColor={backgroundColor ? backgroundColor.hex : 'transparent'}
        px={90}
        py={20}
        width={1}
      >
        {subtitleText && (
          <SubTitle fontSize={[18]} lineHeight={[13]} letterSpacing={[13]}>
            {subtitleText}
          </SubTitle>
        )}
        {titleText && titleTextUrl ? (
          <LinkCTA
            target={titleUrlOpenInNewTab ? '_blank' : '_self'}
            to={titleTextUrl}
            title={titleText}
          >
            <Title mt={20} level={3} linkText={linkText}>
              {titleText}
            </Title>
          </LinkCTA>
        ) : titleText ? (
          <Title mt={20} level={3} linkText={linkText}>
            {titleText}
          </Title>
        ) : null}
        {linkText ? (
          <LinkBox>
            <LinkCTA
              target={linkShouldOpenInNewWindow ? '_blank' : '_self'}
              to={linkUrl}
              title={linkText}
            >
              <LinkText fontSize={[8]} lineHeight={[12]} letterSpacing={[12]}>
                {linkText}
              </LinkText>
            </LinkCTA>
          </LinkBox>
        ) : null}
        <Box>
          {shouldShowDrewPickIcon ? (
            <BoxIcon drewPickIconStyle={drewPickIconStyle} width={75} height={75} px={12} py={22}>
              {drewPickIconText}
            </BoxIcon>
          ) : null}
        </Box>
      </BlockContainer>
    </>
  )
}

export default SingleColumnCenterAligned
