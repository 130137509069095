import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import { FlowerCursorMenu } from '@/components/Cursor'
import { Heading, Text } from '@/components/text'
import Link from '@/components/Link'
import theme from '@/style/theme'
import { Flex, Box } from '@/components/layout'

const HOME_CTA_TEXT = ['H', 'O', 'M', 'E']
const MENU_CTA_TEXT = ['M', 'E', 'N', 'U']

const StyledLink = styled(Link)<any>`
  flex: 0;
  display: inline-block;
  text-decoration: none;
`
interface HomeButtonWrapperPorps {
  homeButton?: Array<Object>
  homeButtonTextColor?: Object
  homeTopBottomTileColor?: object
}

const HomeButtonWrapperStyled = styled(Flex)`
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-left: 2px solid ${p => p.theme.colors.black};
`
const HomeButtonItem = styled(Box)<any>`
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor};
  flex: 1;
  position: relative;
`
const HomeButtonItemText = styled(Text)<any>`
  color: ${props => props.color};
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const HomeButtonWrapper: React.FunctionComponent<HomeButtonWrapperPorps & any> = props => {
  const { homeButton, homeButtonTextColor, homeTopBottomTileColor, isopenmenulistitem } = props
  return (
    <HomeButtonWrapperStyled>
      <HomeButtonItem backgroundColor={homeTopBottomTileColor[0].topTile.hex}>
        {isopenmenulistitem && <CloseIconBox {...props} />}
      </HomeButtonItem>
      {homeButton &&
        homeButton.map((item: any, index: any) => {
          return (
            <HomeButtonItem key={item.id} backgroundColor={item.colorItem.hex}>
              <HomeButtonItemText
                fontSize={[10]}
                color={homeButtonTextColor && homeButtonTextColor.hex}
              >
                {HOME_CTA_TEXT[index]}
              </HomeButtonItemText>
            </HomeButtonItem>
          )
        })}
      <HomeButtonItem backgroundColor={homeTopBottomTileColor[0].bottomTile.hex} />
    </HomeButtonWrapperStyled>
  )
}

export interface CloseIconBoxProps {
  toggleMenuList?: Function
  isOpenMenuList?: boolean
  closeIcon?: string
  dotIcon?: string
}

const CloseIconCtaKeyframes = keyframes`
  0% { transform: scale(0,0) rotate(0deg) };
  100% { transform: scale(1,1) rotate(180deg) };
`

const CloseIconKeyframes = keyframes`
  0% { transform: scale(0,0) };
  100% { transform: scale(1,1) };
`

const DotIconKeyframes = keyframes`
  0% { transform: scale(1,1) };
  100% { transform: scale(0,0) };
`

const ReverseCloseIconCtaKeyframes = keyframes`
  0% { transform: scale(1,1) rotate(180deg)  };
  100% { transform: scale(0,0) rotate(0deg) };
`

const ReverseCloseIconKeyframes = keyframes`
  0% { transform: scale(1,1)  };
  100% { transform: scale(0,0) };
`

const ReverseDotIconKeyframes = keyframes`
  0% { transform: scale(0,0) };
  100% { transform: scale(1,1) };
`

const CrossIconCta = styled(StyledLink)`
  animation: ${props =>
      props.isopenmenulistitem ? CloseIconCtaKeyframes : ReverseCloseIconCtaKeyframes}
    0.7s linear 0.7s forwards;
  position: relative;
`

const CrossIcon = styled.img<any>`
  width: ${32 / 16}rem;
  height: auto;
  transform: scale(0, 0);
  animation: ${props => (props.isopenmenulistitem ? CloseIconKeyframes : ReverseCloseIconKeyframes)}
    0.7s linear 0.7s forwards;
`

const DotIcon = styled.img<any>`
  width: ${32 / 16}rem;
  height: auto;
  transform: scale(0, 0);
  animation: ${props => (props.isopenmenulistitem ? DotIconKeyframes : ReverseDotIconKeyframes)}
    0.7s linear 0.7s forwards;
  position: absolute;
  right: 0;
  top: 0;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const CloseIconBox: React.FunctionComponent<CloseIconBoxProps & any> = props => {
  const { toggleMenuList, isOpenMenuList, closeIcon, dotIcon, isopenmenulistitem } = props

  return (
    <CloseIconWrapper>
      <CrossIconCta
        target="_blank"
        to="/#"
        isopenmenulistitem={isopenmenulistitem}
        onClick={e => {
          e.preventDefault()
          if (isOpenMenuList) toggleMenuList()
        }}
      >
        <CrossIcon isopenmenulistitem={isopenmenulistitem} src={closeIcon} />
        <DotIcon isopenmenulistitem={isopenmenulistitem} src={dotIcon} />
      </CrossIconCta>
    </CloseIconWrapper>
  )
}

export interface MenuItemsProps {
  id?: string
  title?: string
  url?: string
  backgroundColor?: object
  textColor?: object
  headingLevel?: number
  siteRelated?: boolean
  toggleMenuList?: Function
  delayLevel?: any
  textShadowColor?: object
}

const MenuItemCta = styled(StyledLink)`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  text-align: center;
  ${FlowerCursorMenu}
`

export interface MenuItemsContainerProps {
  bgColor?: string
  isopenmenulistitem?: boolean
  delayLevel?: any
}

const ItemsKeyframes = keyframes`
  0% {
    width: 0;
  }
  
  100% {
    width: 50%;
  }
`

const ReverseItemsKeyframes = keyframes`
  0% {
    width: 50%;
  }

  100% {
    width: 0
  }
`

const MenuItemsContainer = styled(Flex)<MenuItemsContainerProps>`
  align-items: center;
  overflow: hidden;
  background-color: ${props => props.bgColor};
  width: ${props => (props.isopenmenulistitem ? 0 : '50%')};
  animation: ${props => (props.isopenmenulistitem ? ItemsKeyframes : ReverseItemsKeyframes)} 0.5s
    linear ${props => props.delayLevel}s forwards;
`
const MenuHeading = styled(Heading)`
  margin-top: ${14 / 16}rem;
  white-space: nowrap;
  width: 100%;
  ${FlowerCursorMenu}
`

const MenuItems: React.FunctionComponent<MenuItemsProps & any> = props => {
  const {
    siteRelated,
    url,
    backgroundColor,
    textColor,
    headingLevel,
    toggleMenuList,
    delayLevel,
    isopenmenulistitem,
    multiColoredTextValue,
    textShadowColor,
  } = props

  let { title } = props

  /*
    TODO: Clean up
    Replaces everything inside parenthesis so it can be styled.
    Use a different approach, DatoCMS allows rich text.
  */

  title = title.replace(/\((.*?)\)/g, (match: any, content: any) => {
    return `<span class="multi-color-text" color: '#fff'>(${content})</span>`
  })

  interface LinkTextProps {
    shadowSpec?: any
    underLine?: boolean
    letterSpacing?: Number
  }

  let textValidated

  const ShadowText = styled(Text)<LinkTextProps>`
    text-shadow: ${props => (props.shadowSpec ? props.shadowSpec : 'none')};
  `

  if (textShadowColor) {
    const shadowSpec = `${theme.shadows[2]} ${(textShadowColor && textShadowColor.hex) ||
      '#B73D89'}`
    textValidated = <ShadowText shadowSpec={shadowSpec}>{title}</ShadowText>
  } else {
    textValidated = <span dangerouslySetInnerHTML={{ __html: title }} />
  }

  return (
    <MenuItemsContainer
      isopenmenulistitem={isopenmenulistitem}
      delayLevel={delayLevel}
      bgColor={backgroundColor.hex}
      ref={a => {
        if (a) {
          const multiColorTextElement = a.querySelector('.multi-color-text')
          if (multiColorTextElement && multiColoredTextValue) {
            multiColorTextElement.style.color = multiColoredTextValue && multiColoredTextValue.hex
          }
        }
      }}
    >
      <MenuItemCta
        target={siteRelated ? '_self' : '_blank'}
        to={url}
        title={title}
        grow={true}
        onClick={() => {
          toggleMenuList()
        }}
      >
        <MenuHeading level={headingLevel} color={textColor.hex}>
          {textValidated}
        </MenuHeading>
      </MenuItemCta>
    </MenuItemsContainer>
  )
}

export interface MenuListProps {
  menuList?: Array<Object>
  homeButton?: string
  isopenmenulistitem?: Boolean
  DesktopSiteReltatedMenuList?: any
  DesktopexternalMenuList?: any
  menuItemsList?: any
}

const MenuListWrapper = styled(Box)`
  max-width: 1100px;
  margin: 0 auto;
  height: 100vh;
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  left: 0;
  ${FlowerCursorMenu}
`
const MenuListContainer = styled(Flex)<any>`
  height: 100%;
  flex-direction: column;
  transform-origin: 0;
`
const MenuItemsRowWrapper = styled(Flex)<any>`
  width: 100%;
  flex: 1;
`

const MenuList: React.FunctionComponent<MenuListProps & any> = props => {
  const {
    menuList,
    homeButton,
    isopenmenulistitem,
    DesktopSiteReltatedMenuList,
    DesktopexternalMenuList,
    menuItemsList,
    ...rest
  } = props

  return (
    <MenuListWrapper>
      <MenuListContainer {...props}>
        {menuItemsList &&
          menuItemsList.map((listItem: any, index: any) => {
            if (index % 2 === 0) {
              return (
                <MenuItemsRowWrapper>
                  <MenuItems
                    delayLevel={index * 0.05}
                    {...props}
                    isopenmenulistitem={isopenmenulistitem}
                    key={listItem.id}
                    {...listItem}
                  />
                  {menuItemsList[index + 1] ? (
                    <MenuItems
                      delayLevel={index * 0.05}
                      {...props}
                      isopenmenulistitem={isopenmenulistitem}
                      key={menuItemsList[index + 1] && menuItemsList[index + 1].id}
                      {...menuItemsList[index + 1]}
                    />
                  ) : null}
                </MenuItemsRowWrapper>
              )
            }
          })}
      </MenuListContainer>
    </MenuListWrapper>
  )
}

export interface MenuButtonProps {
  menuWidth?: Number
  toggleMenuList?: Function
  menuButtonTextColor?: Object
  menuTopBottomTileColor?: Object
}

const HeaderMenuButtonWrapper = styled(Box)<any>`
  width: ${props => props.menuWidth / 16}rem;
  height: 100%;
  border-right: 2px solid ${p => p.theme.colors.black};
`

const HeaderMenuButton = styled(StyledLink)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const MenuButtonItem = styled(Box)<any>`
  position: relative;
  background-color: ${props => props.backgroundColor};
  flex: 1;
`

const MenuButtonItemText = styled(Text)<any>`
  color: ${props => props.color};
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const MenuButton: React.FunctionComponent<MenuButtonProps & any> = props => {
  const {
    menuWidth,
    toggleMenuList,
    isOpenMenuList,
    menuButtonColor,
    menuButtonTextColor,
    menuTopBottomTileColor,
  } = props

  return (
    <HeaderMenuButtonWrapper menuWidth={menuWidth}>
      <HeaderMenuButton
        target="_blank"
        to="/#"
        onClick={e => {
          e.preventDefault()
          if (!isOpenMenuList) toggleMenuList()
        }}
      >
        <MenuButtonItem backgroundColor={menuTopBottomTileColor[0].topTile.hex} />
        {menuButtonColor &&
          menuButtonColor.map((item: any, index: any) => {
            return (
              <MenuButtonItem
                backgroundColor={item.menuColorItem && item.menuColorItem.hex}
                key={item.id}
              >
                <MenuButtonItemText
                  fontSize={[10]}
                  color={menuButtonTextColor && menuButtonTextColor.hex}
                >
                  {MENU_CTA_TEXT[index]}
                </MenuButtonItemText>
              </MenuButtonItem>
            )
          })}
        <MenuButtonItem backgroundColor={menuTopBottomTileColor[0].bottomTile.hex} />
      </HeaderMenuButton>
    </HeaderMenuButtonWrapper>
  )
}

export interface HeaderContainerProps {
  menuList?: Array<Object>
  homeButton?: string
  isOpenMenuList?: boolean
  isopenmenulistitem?: boolean
  menuWidth?: number
  DesktopSiteReltatedMenuList?: any
  DesktopexternalMenuList?: any
  toggleMenuList?: Function
}

const HeaderContainerBox = styled(Box)<any>`
  position: fixed;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: #000;
`
const HomeButtonContainer = styled(Box)<any>`
  width: ${props => props.menuWidth / 16}rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #000;
`
const HomeButton = styled(Link)<any>`
  flex: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  text-decoration: none;
`

const HeaderContainer: React.FunctionComponent<HeaderContainerProps & any> = props => {
  const { isOpenMenuList, isopenmenulistitem, menuWidth, toggleMenuList } = props

  return (
    <>
      <HeaderContainerBox
        height={isOpenMenuList ? '100vh' : 'auto'}
        isOpenMenuList={isOpenMenuList}
        isopenmenulistitem={isopenmenulistitem}
        {...props}
      >
        <MenuButton {...props} />
      </HeaderContainerBox>
      {isOpenMenuList ? <MenuList isopenmenulistitem={isopenmenulistitem} {...props} /> : null}
      <HomeButtonContainer menuWidth={menuWidth}>
        <HomeButton
          to="/"
          onClick={() => {
            if (isopenmenulistitem) toggleMenuList()
          }}
        >
          <HomeButtonWrapper {...props} />
        </HomeButton>
      </HomeButtonContainer>
    </>
  )
}

export interface HeaderProps {
  data?: object
  toggleMenuList?: Function
  isOpenMenuList?: boolean
}

export const DesktopHeader: React.FunctionComponent<HeaderProps & any> = props => {
  const {
    data,
    DesktopSiteReltatedMenuList,
    DesktopexternalMenuList,
    isopenmenulistitem,
    ScrollBarWidth,
    DesktopMenuWidth,
    menuItemsList,
  } = props

  const scrollWidth = !isopenmenulistitem ? ScrollBarWidth : 0
  let menuWidth = (DesktopMenuWidth - 1024 - scrollWidth) / 2

  return (
    <HeaderContainer
      data
      menuWidth={menuWidth}
      menuList={data.menuList}
      homeButton={data.homeButtonColors}
      homeButtonTextColor={data.homeButtonTextColor}
      menuButtonTextColor={data.menuButtonTextColor}
      closeIcon={data.closeIcon && data.closeIcon.url}
      dotIcon={data.dotIcon && data.dotIcon.url}
      menuButtonColor={data.desktopMenuButtonColor}
      DesktopSiteReltatedMenuList={DesktopSiteReltatedMenuList}
      menuItemsList={menuItemsList}
      DesktopexternalMenuList={DesktopexternalMenuList}
      homeTopBottomTileColor={data.homeTopBottomTileColor}
      menuTopBottomTileColor={data.menuTopBottomTileColor}
      {...props}
    />
  )
}
