import React from 'react'
import styled from '@emotion/styled'

import Link from '@/components/Link'
import ResponsiveImage, { BreakpointKeyedObject } from '@/components/ResponsiveImage'
import { Heading } from '@/components/text'
import { Box } from '@/components/layout'

interface FullBleedImageShadowBoxProps {
  imageAsset: BreakpointKeyedObject
  boxText: String
  boxTextUrl?: String
  boxTextType: String
  boxTextColor: Object
  boxBackgroundColor: Object
  boxShadowColor: Object
  shouldBeFullScreenImage?: Boolean
}

const ShadowBox = styled(Box)<any>`
  width: 70%;
  padding: 0;
  position: absolute;
  bottom: ${props => props.positionBottom}rem;
  left: 0;
  z-index: 1;
  background-color: ${props =>
    props.boxBackgroundColor ? props.boxBackgroundColor : 'transparent'};
  box-shadow: ${props => (props.boxShadowColor ? `0.6rem 0.6rem ${props.boxShadowColor}` : 'none')};
`

const Title = styled(Heading)<any>`
  margin: ${props => (props.m ? props.m : 'unset')};
  padding: ${props => `${props.pt ? props.pt / 16 : 0}rem ${props.px ? props.px / 16 : 0}rem 0`};
  text-align: center;
  line-height: unset;
`

const FullBleedImageShadowBox: React.FunctionComponent<FullBleedImageShadowBoxProps> = props => {
  const {
    imageAsset,
    boxText,
    boxTextUrl,
    boxTextType,
    boxTextColor,
    boxBackgroundColor,
    boxShadowColor,
    shouldBeFullScreenImage,
    ...rest
  } = props

  const titleLevel = boxTextType ? parseInt(boxTextType.replace(/\D/g, '')) : 0
  return (
    <Box
      ref={a => {
        if (a) {
          let shadoBoxHeight =
            a.querySelector('.shadow-box') && a.querySelector('.shadow-box').clientHeight
          let BoxContainerHeight =
            a.querySelector('.Box-container') && a.querySelector('.Box-container').clientHeight

          if (shadoBoxHeight > BoxContainerHeight) {
            a.querySelector('.gatsby-image-wrapper').style.height = `${shadoBoxHeight + 50}px`
          }
        }
      }}
    >
      <Box position={'relative'} className="Box-container">
        {boxText && (
          <ShadowBox
            boxBackgroundColor={boxBackgroundColor ? boxBackgroundColor.hex : false}
            boxShadowColor={boxShadowColor ? boxShadowColor.hex : false}
            positionBottom={30 / 16}
          >
            {boxTextUrl ? (
              <Link to={boxTextUrl}>
                <Title
                  m={0}
                  pt={15}
                  px={25}
                  fontSize={[3, 4, 5]}
                  color={boxTextColor.hex}
                  level={titleLevel}
                >
                  {boxText}
                </Title>
              </Link>
            ) : (
              <Title
                m={0}
                pt={15}
                px={25}
                fontSize={[3, 4, 5]}
                color={boxTextColor.hex}
                level={titleLevel}
              >
                {boxText}
              </Title>
            )}
          </ShadowBox>
        )}
        <Box
          ref={a => {
            if (a && shouldBeFullScreenImage) {
              //default page load
              a.querySelector('.gatsby-image-wrapper').style.height = '100vh'

              //on resize screen
              if (typeof window !== `undefined`) {
                window.onresize = () => {
                  a.querySelector('.gatsby-image-wrapper').style.height = '100vh'
                }
              }
            }
          }}
        >
          <ResponsiveImage fluid={imageAsset} {...rest} />
        </Box>
      </Box>
    </Box>
  )
}

export default FullBleedImageShadowBox
