import React from 'react'
import { graphql } from 'gatsby'
import { SectionProps } from '@/containers/SectionFactory'
import { SingleColumnLeftAlignedButton } from '@/components/SingleColumnLeftAlignedButtonComponent'

interface SingleColumnLeftAlignedWithButtonSectionProps extends SectionProps {
    subtitleText?:string
    subtitleType?: string
    subtitleColor?: object
    titleText?: string
    titleType?: string
    titleColor?: object
    backgroundColor?: object
    copyText?: string
    copyColor?: object
    separatorHeight?: number
    ctaText?: string
    ctaUrl?: string
    ctaShouldOpenInNewWindow?: boolean
    ctaShadowColor?: object
    ctaBackgroundColor?: object
    ctaTextColor?: object,
    theme?: any
}



const SingleColumnLeftAlignedWithButtonSection: React.FunctionComponent<SingleColumnLeftAlignedWithButtonSectionProps> = props => {

  const {
    subtitleType,
    titleType
  } = props
  const subtitleTypeLevel = subtitleType && subtitleType.slice(7) || '4'
  const titleTypeLevel = titleType && titleType.slice(7) || '2'

  // titleHeadingSpacing & subTitleHeadingSpacing array is for margin/padding for heading elements
  const titleHeadingSpacing = [25/16, 20/16, 30/16, 30/16, 30/16, 30/16]
  const titleSpacing = titleHeadingSpacing[titleTypeLevel && parseInt(titleTypeLevel)-1] 

  const subTitleHeadingSpacing = [0, 0, 0, 0, 0, 20/16]
  const subTitleSpacing = subTitleHeadingSpacing[subtitleTypeLevel && parseInt(subtitleTypeLevel)-1] 

  return (
    <>
      <SingleColumnLeftAlignedButton 
        {...props}
        subtitleTypeLevel= {subtitleTypeLevel}
        titleTypeLevel= {titleTypeLevel}
        titleSpacing= {titleSpacing}
        subTitleSpacing= {subTitleSpacing}
      />
      
    </>
  )
}

export default SingleColumnLeftAlignedWithButtonSection

export const type = 'DatoCmsSingleColumnLeftAlignedWithButtonSection'
export const query = graphql`
  fragment SingleColumnLeftAlignedWithButtonSection on DatoCmsSingleColumnLeftAlignedWithButtonSection {
    subtitleText
    subtitleType
    subtitleColor {
      hex
    }
    titleText
    titleType
    titleColor {
      hex
    }
    backgroundColor {
      hex
    }
    copyText
    separatorHeight
    copyColor {
      hex
    }
    ctaText
    ctaUrl
    ctaTextColor {
      hex
    }
    ctaShadowColor {
      hex
    }
    ctaBackgroundColor {
      hex
    }
    ctaShouldOpenInNewWindow
  }
`
