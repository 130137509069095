import React from 'react'
import styled from '@emotion/styled'

import  theme from '@/style/theme'
import ResponsiveImage from '@/components/ResponsiveImage'
import { Flex, Box } from '@/components/layout'
import { Heading, Text } from '@/components/text'
import FormatText from '@/utils/FormatText'

const ArticleSectionsContainer = ({ sections }: any) => {
  return sections.map((section: any) => {
    if (section.__typename == "DatoCmsInlineImageParagraph") {
      return InlineImageSection(section)
    }

    else if (section.__typename == "DatoCmsTextParagraph") {
      return ParagraphSection(section)
    }

    else return null
  })
}

const Reverser = styled(Flex)<any>`
  flex-direction: ${ props => props.reverseOrder ? 'row-reverse' : 'row' };
`

const Paragraph = styled(Text)`
  max-width: 900px;
`

const ParagraphReverser = styled(Paragraph)<any>`
  ${ props => props.reverseOrder ? 'margin-right: auto' : 'margin-left: auto' }
`

const InlineImageSection = (props: any) => {
  const { 
    rightAlignedImage, 
    image, 
    text, 
    textType, 
    id 
  } = props

  return (
    <Reverser 
      key={id} 
      my={3} 
      justifyContent={'center'} 
      alignItems={'center'} 
      reverseOrder={rightAlignedImage}>
      <Flex style={{flexBasis: '60%'}}>
        <ParagraphReverser 
          dangerouslySetInnerHTML={{__html: FormatText(text)}} 
          fontSize={[9, 8, textType ==='smallBody' ? 8 : 6]} 
          fontFamily={textType===('smallBody') ? theme.fontFaces.link : theme.fontFaces.body}
          reverseOrder={rightAlignedImage}
          /> 
      </Flex>

      <Box style={{ flex: 1}}>
        <ResponsiveImage fluid={image} />
      </Box>
    </Reverser>
  )
}

const ParagraphSection = (props: any) => {
  const { text, textType, id } = props
  return (
    <Flex my={3} key={id}>
      <Paragraph 
        dangerouslySetInnerHTML={{__html: FormatText(text)}} 
        fontSize={[9, 8, textType ==='smallBody' ? 8 : 6]} 
        fontFamily={textType===('smallBody') ? theme.fontFaces.link : theme.fontFaces.body} />
    </Flex>
  )
}

interface ArticleSectionProps {
  sections: Array<any>
  title: String
  articleBackgroundColor: Object
  titleTextColor: Object
}

const ArticleSectionsWrapper = styled(Box)<any>`
  background-color: ${props => props.backgroundColor};
  padding: ${props => `${props.py}rem ${props.px}rem`};
`

const ArticleSection: React.FunctionComponent<ArticleSectionProps & any> = props => {
  const {
    sections,
    title,
    articleBackgroundColor,
    titleTextColor,
    footerNotes,
    footerNotesColor
  } = props;

  return (
    <ArticleSectionsWrapper
      px={20/16}
      py={30/16} 
      backgroundColor={articleBackgroundColor && articleBackgroundColor.hex}>
      {
        title && <Heading level={3} color={titleTextColor ? titleTextColor.hex : '#000'}>{title}</Heading>
      }
      <ArticleSectionsContainer
        sections= {sections}
      />
      {
        footerNotes && 
        <Paragraph fontSize={[9, 8, 6]} color={footerNotesColor ? footerNotesColor.hex : '#000'} dangerouslySetInnerHTML={{__html: FormatText(footerNotes)}} />
      }
    </ArticleSectionsWrapper>
  )
}

export default ArticleSection