
import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import { Box } from '@/components/layout'
import { Heading, Text, ErrorText } from '@/components/text';
import { theme } from '@/style';
import { FlowerCursorActive } from '@/components/Cursor'

interface NewsLetterSectionComponentPorps {
  titleText: string
  titleTextColor: object
  copyText: string
  copyTextColor: object
  placeholderText: string
  inputTextColor: object
  ctaText: string
  ctaTextColor: object
  backgroundColor: object
  backgroundImageAsset: object
  emailError: string
  onSubmitEmail: Function
  onEmailInputChange: Function
  email: string
  isShowSuccessMessage: boolean
  successMessageText: string
  successMessageTextColor: Object
  ctaTextShadowColor: object
}

const NewsLetterSectionContainer = styled(Box)<any>`
  padding: ${25/16}rem ${40/16}rem;
  background: ${props => props.backgroundColor} url(${props => props.backgroundImage}) no-repeat;
`
const NewsLetterContentWrapper = styled(Box)`
  background-color: #292728;
  padding: ${20/16}rem ${20/16}rem ${30/16}rem;
`

const NewsLetterHeading = styled(Heading)`
  letter-spacing: unset;
  margin: 0;
`

const CopyTextStyled = styled(Text)`
  margin: ${15/16}rem 0;
  display: block;
`

const InputStyled = styled.input<any> `
  width: 100%;
  padding: ${9/16}rem ${props => props.pr}rem ${5/16}rem ${10/16}rem;
  outline: none;
  border: none;
  box-sizing: border-box;
  color: ${props => props.color};
  font-family: ${theme.fontFaces.largeBody};
  font-size: ${theme.fontSizes[6]};
  line-height: unset;
  &::placeholder {
    color: ${props => props.color};
    font-family: ${theme.fontFaces.largeBody};
    font-size: ${theme.fontSizes[6]};
    line-height: unset;
  }
`

const InputButtonWrapper = styled(Box)`
  width: 100%;
  position: relative;
`
const ButtonStyled = styled.button<any> `
  border: none;
  outline: none;
  padding: 0 ${10/16}rem;
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: 0;
  ${FlowerCursorActive}
  transform: translate(0, -50%);
  margin-top: ${2/16}rem;
  &:hover {
    text-shadow: ${props => props.shadowSpec ? props.shadowSpec : 'none'};
  }
`
const ButtonHeading = styled(Heading)`
  margin: 0;
  line-height: unset;
`

const ErrorTextStyled = styled(ErrorText)`
  margin-top: ${5/16}rem;
  display: block;
  position: absolute;
  bottom: -10;
  left: 0;
`
const MenuItemsKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`

const SuccessMessageContainer = styled(Box)`
  width: 100%;
  padding: ${9/16}rem ${10/16}rem ${5/16}rem;
  box-sizing: border-box;
  background-color: #fff;
  animation: ${MenuItemsKeyframes} .7s linear forwards;
`
const SuccessText = styled(Text)`
  color: ${props => props.color};
  font-family: ${theme.fontFaces.largeBody};
  font-size: ${theme.fontSizes[6]};
  line-height: unset;
`

export class NewsLetterSectionComponent extends React.Component<NewsLetterSectionComponentPorps & any> {
  
  state = {
    inputRightPadding: 10,
    isRender: false
  }
  render() {
    const {
      titleText,
      titleTextColor,
      copyText,
      copyTextColor,
      placeholderText,
      inputTextColor,
      ctaText,
      ctaTextColor,
      backgroundColor,
      backgroundImageAsset,
      onSubmitEmail,
      emailError,
      onEmailInputChange,
      email,
      isShowSuccessMessage,
      successMessageText,
      successMessageTextColor,
      ctaTextShadowColor,
    } = this.props
    let {
      inputRightPadding,
      isRender
    } = this.state

    return (
      <NewsLetterSectionContainer
        backgroundImage={backgroundImageAsset && backgroundImageAsset.url ? backgroundImageAsset.url : ''} 
        backgroundColor={backgroundColor && backgroundColor.hex}
      >
        <NewsLetterContentWrapper>
          <NewsLetterHeading 
            level={3} 
            color={titleTextColor && titleTextColor.hex}
          >
            {titleText}
          </NewsLetterHeading>
          
          <CopyTextStyled color={copyTextColor && copyTextColor.hex}>{copyText}</CopyTextStyled>
  
          <form onSubmit={onSubmitEmail} method="post">
          {
            isShowSuccessMessage ?
            <SuccessMessageContainer>
              <SuccessText
                color={successMessageTextColor && successMessageTextColor.hex}
              >
                {successMessageText}
              </SuccessText>
            </SuccessMessageContainer> :
            <InputButtonWrapper 
              ref={a => {
                if(a) {
                  inputRightPadding = a.getElementsByTagName('button')[0].clientWidth
                  if(!isRender) {
                    this.setState({
                      inputRightPadding: inputRightPadding,
                      isRender: true
                    })
                  }
                }
              }}
            > 
              <InputStyled 
                type="text" 
                name="email" 
                id="email" 
                value={email}
                pr={inputRightPadding/16} 
                placeholder={placeholderText}
                onChange={onEmailInputChange}
                color={inputTextColor && inputTextColor.hex}
              />
              <ErrorTextStyled>{emailError}</ErrorTextStyled>

              <ButtonStyled 
                type="submit"
                shadowSpec={
                  `${theme.shadows[2]} ${ctaTextShadowColor && ctaTextShadowColor.hex || '#B73D89'}`
                }
              >
                <ButtonHeading 
                  level={4} 
                  color={ctaTextColor}
                >
                  {ctaText}
                </ButtonHeading>
              </ButtonStyled>
            </InputButtonWrapper>
          }
          </form>
        </NewsLetterContentWrapper>
      </NewsLetterSectionContainer>
    )
  }
}