import React from 'react'
import { graphql } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { SectionProps } from '@/containers/SectionFactory'
import { NewsLetterSectionComponent } from '@/components/NewsLetterSectionComponent'
import EmailValidator from '@/utils/EmailValidator'


interface NewsLetterSectionProps extends SectionProps {
  titleText: string
  titleTextColor: object
  copyText: string
  copyTextColor: object
  placeholderText: string
  inputTextColor: object
  ctaUrl: string
  ctaText: string
  ctaTextColor: object
  backgroundColor: object
  backgroundImageAsset: object
  failureMessage: string
  successMessageText: string
  successMessageTextColor: Object
  ctaTextShadowColor: object
}

class  NewsLetterSection extends React.Component<NewsLetterSectionProps> {
  state = {
    email: '',
    emailError: '',
    isShowSuccessMessage: false
  }

  onSubmitEmail =  (e) => {
    e.preventDefault()

    const {
      failureMessage
    } = this.props

    const {
      email
    } = this.state

    if(email && email.trim() !== "") {
      let isEmailValidated = EmailValidator(email)
      if(isEmailValidated) {
        addToMailchimp(email)
          .then((data: any) => {
            if(data && data.result === 'success') {
              this.setState({
                emailError: '',
                email: '',
                isShowSuccessMessage: true
              })
            } else {
              this.setState({
                emailError: failureMessage,
                isShowSuccessMessage: false
              })
            }
          })
      } else {
        this.setState({
          emailError: failureMessage
        })
      }
    } else {
      this.setState({
        emailError: failureMessage
      })
    }
    return false
  }

  _onEmailInputChange = (e) => {
    this.setState({
      email: e.target.value,
      emailError: ''
    })
  }
  render() {
    return (
      <>
        <NewsLetterSectionComponent 
          onSubmitEmail={this.onSubmitEmail}
          onEmailInputChange={this._onEmailInputChange}
          {...this.state}
          {...this.props}
        />
      </>
    )
  }
}

export default NewsLetterSection 

export const type = 'DatoCmsNewsletterSection'
export const query = graphql`
  fragment NewsLetterSection on DatoCmsNewsletterSection {
    titleText
    titleTextColor {
      hex
    }
    copyText
    copyTextColor {
      hex
    }
    
    placeholderText
    inputTextColor {
      hex
    }
    ctaText
    ctaTextColor {
      hex
    }
    backgroundColor {
      hex
    }
    backgroundImageAsset {
      url
    }
    successMessageText
    successMessageTextColor {
      hex
    }
    failureMessage
    ctaTextShadowColor {
      hex
    }
  } 
`