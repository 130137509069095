import React from 'react'
import { graphql } from 'gatsby'
import FullBleedImageShadowBox from '@/components/FullBleedImageShadowBoxComponent'

const FullBleedImageShadowBoxContainer: React.FunctionComponent<any> = props => {
  return (
    <FullBleedImageShadowBox {...props}/>
  )
}

export default FullBleedImageShadowBoxContainer

export const type = 'DatoCmsFullBleedImageWithShadowBoxSection'
export const query = graphql`
  fragment FullBleedImageShadowBoxContainer on DatoCmsFullBleedImageWithShadowBoxSection {
    imageAsset {
      xs: fluid(imgixParams: { auto: "format,compress" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
      sm: fluid(imgixParams: { auto: "format,compress" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }
    
    boxText
    boxTextUrl
    boxTextType
    boxTextColor {
      hex
    }
    shouldBeFullScreenImage
    boxBackgroundColor {
      hex
    }
    boxShadowColor {
      hex
    }
	}
`