import React from 'react'
import styled from '@emotion/styled'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import ResponsiveImage, { BreakpointKeyedObject } from '@/components/ResponsiveImage'
import { SectionProps } from '@/containers/SectionFactory'
import { Flex, Box } from '@/components/layout'
import { VideoModal } from '@/components/ModalPlayer'
import { FlowerCursorActive } from '@/components/Cursor'



interface VideoProps extends SectionProps  {
  videoFileUpload: Object
  videoFileLink: Object
  backgroundImage: BreakpointKeyedObject
  playIcon: BreakpointKeyedObject
}

const PlayLink = styled(Box)<any>`  
  width: 25%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  &:hover {
    ${FlowerCursorActive}
  }
`

class Content extends React.Component<VideoProps & any> {
  constructor(props:any) {
    super(props)
    this.state = {
      isModalOpen: false
    }
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
  }

  handleCloseModal = () => {
    this.setState({isModalOpen: false})
    enableBodyScroll()
  }

  handleOpenModal = () => {
    this.setState({isModalOpen: true})
    disableBodyScroll()
  }
  render () {
    const { 
      videoFile,
      videoUrl,
      backgroundImage,
      playIcon  
    } = this.props
    return (
      <Box flex={1} className='hello' position={'relative'}>
        <Flex flex={1} >
          <Box width={1}>
          <PlayLink onClick={this.handleOpenModal}>
            <ResponsiveImage fluid={playIcon} />
          </PlayLink>
          <VideoModal
            isOpen={this.state.isModalOpen}
            videoUrl={videoFile ? videoFile.url : videoUrl ? videoUrl : null}
            contentLabel='Video'
            onRequestClose={this.handleCloseModal}  
            controls={true}
          />
          </Box>
        </Flex>
        <ResponsiveImage fluid={backgroundImage} />
      </Box>
    )
  }
}


interface VideoComponentProps {
  video?: Array<object>
}

const VideoComponent: React.FunctionComponent<VideoComponentProps & any> = props => {
  return props.video.map((videoData: any) => {
    const typename = videoData.__typename
    
    if (typename == 'DatoCmsVideoFileUpload') {
      return <Content key={videoData.id}  backgroundImage={props.backgroundImage} playIcon={props.playIcon} {...videoData} />

    } else if (typename == 'DatoCmsVideoFileLink') {
      return <Content key={videoData.id} backgroundImage={props.backgroundImage} playIcon={props.playIcon} {...videoData} />
    }

    return null 
  })
}

interface VideoPlayerProps {
  video?: Object
}

export const VideoPlayer: React.FunctionComponent<VideoPlayerProps & any> = props => {
  return (
    <Flex>
      <VideoComponent {...props} tiles={props.video} />
    </Flex>
  )
}