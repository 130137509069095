import React from 'react'
import { graphql } from 'gatsby'

import { SectionProps } from '@/containers/SectionFactory'
import { VideoPlayer } from '@/components/VideoPlayerSection'

interface VideoPlayerSectionProps extends SectionProps {
  tiles?: Array<Object>
}

const VideoPlayerContainer: React.SFC<VideoPlayerSectionProps> = props => {
  return (
    <>
      <VideoPlayer {...props} />
    </>
  )
}

export default VideoPlayerContainer

export const type = 'DatoCmsVideoPlayerSectionModule'
export const query = graphql`
  fragment VideoPlayerSectionModule on DatoCmsVideoPlayerSectionModule {
    id
    backgroundImage {
      xs: fluid(imgixParams: { auto: "format,compress" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
      sm: fluid(imgixParams: { auto: "format,compress" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }
    playIcon {
      xs: fluid(imgixParams: { auto: "format,compress" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
      sm: fluid(imgixParams: { auto: "format,compress" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }
    video {
      ... on DatoCmsVideoFileUpload {
        id
        videoFile {
          url
        }
      }
      ... on DatoCmsVideoFileLink {
        id
        videoUrl
      }
    }
  }
`
