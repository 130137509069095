import React from 'react'
import styled from '@emotion/styled'
import { jsx, css, keyframes } from '@emotion/core'
import { compose, withHandlers } from 'recompose'
import { fontSize, themeGet, space } from 'styled-system'

import { FlowerCursorActive } from '@/components/Cursor'
import { Flex, Box } from '@/components/layout'

export interface RoundedButtonProps {
  download?: boolean
  to?: string
  secondary?: boolean
  isLoading?: boolean
  disabled?: boolean
  handleClick?: () => void
  children?: any
}

interface ButtonContentProps {
  isLoading: Boolean
  children: any
}

const ButtonContent = styled(Flex)<ButtonContentProps>`
  ${props =>
    props.isLoading && {
      transition: 'all 0.2s ease-in-out',
      opacity: 0,
    }};
`

const DisabledStyle = p => css`
  opacity: ${p.secondary ? 0.5 : 0.8};
  background-color: ${p.secondary ? p.theme.colors.gray[6] : '#e5e5e5'};
  border: 2px solid ${p.secondary ? p.theme.colors.gray[5] : '#e5e5e5'};
  ${FlowerCursorActive}
`

const Transitioned = css`
  &:hover {
    background-color: transparent;
    border: 2px solid rgba(0, 0, 0, 0);
  }

  opacity: 0;
  border: 2px solid rgba(0, 0, 0, 0);
  transform: scale(0.7);
`

const StyledLink = styled.a`
  color: ${themeGet('colors.primary')};
  flex: 0;
  display: inline-block;
  text-decoration: none;
`

export const RoundedButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${FlowerCursorActive}
  border-radius: 2.3em;
  max-width: 100%;
  padding-top: 0.6em;
  padding-bottom: 0.55em;
  transform: scale(1);
  ${fontSize};
  ${space};

  ${p => ({
    fontWeight: p.theme.fontWeights.bold,
    backgroundColor: p.secondary ? p.theme.colors.transparent : p.theme.colors.secondary,
    color: p.secondary ? p.theme.colors.secondary : p.theme.colors.primary,
    border: `2px solid ${p.theme.colors.secondary} `,

    '&:hover': {
      backgroundColor: p.secondary ? p.theme.colors.gray[6] : '#e5e5e5',
      border: `2px solid ${p.secondary ? p.theme.colors.secondary : '#e5e5e5'}`,
    },
  })};

  &:active {
    transition: transform 200ms ease 0s;
    transform: ${p => (p.isLoading || p.disabled ? `scale(1)` : `scale(0.95)`)};
  }

  &:disabled {
    background-color: blue;
  }

  transition: transform 500ms ease-in-out, opacity 300ms ease-in-out,
    scale 0ms 300ms border 200ms 100ms;

  ${p => (p.isLoading || p.disabled ? DisabledStyle : null)};
  ${p => (p.transitioned ? Transitioned : null)};
`

interface IconProps {
  src: string
}

const Icon = styled(Box)<IconProps>`
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: 1rem;

  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

const enhance = compose(
  withHandlers({
    handleClick: (p: any) => (event: any) => {
      const { handleClick } = p
      if (handleClick) {
        event.preventDefault()
        event.stopPropagation()
        handleClick()
      }
    },
  })
)

const RoundedButton: React.FunctionComponent<RoundedButtonProps & any> = enhance(props => {
  const {
    transitioned,
    disabled,
    download,
    icon,
    secondary,
    isLoading,
    children,
    handleClick,
    to,
    ...rest
  } = props

  if (to) {
    return (
      <Box {...props}>
        <StyledLink
          target="_blank"
          href={to}
          download={download}
          onClick={e => {
            if (!isLoading && handleClick) {
              handleClick(e)
            }

            if (handleClick) {
              e.stopPropagation()
            }

            if (handleClick && !to) {
              e.preventDefault()
            }
          }}
        >
          <RoundedButtonContainer
            isLoading={isLoading}
            secondary={secondary}
            disabled={disabled}
            px={['1em', '3em']}
            transitioned={transitioned}
          >
            <ButtonContent
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              isLoading={isLoading}
              {...rest}
            >
              {icon ? <Icon src={icon} /> : null}
              {children}
            </ButtonContent>

            {isLoading ? <Loader secondary={secondary} /> : null}
          </RoundedButtonContainer>
        </StyledLink>
      </Box>
    )
  } else {
    return (
      <Box {...props}>
        <RoundedButtonContainer
          onClick={handleClick}
          isLoading={isLoading}
          secondary={secondary}
          disabled={disabled}
          px={['1em', '3em']}
          transitioned={transitioned}
        >
          <ButtonContent
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            isLoading={isLoading}
          >
            {icon ? <Icon src={icon} /> : null}
            {children}
          </ButtonContent>
          {isLoading ? <Loader secondary={secondary} /> : null}
        </RoundedButtonContainer>
      </Box>
    )
  }
})

export default RoundedButton

const LoadingKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
`

interface LoaderProps {
  secondary?: Boolean
}

export const Loader = styled(Box)<LoaderProps>`
  ::after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
  }

  border-radius: 50%;
  width: 1em;
  height: 1em;

  box-sizing: border-box;
  margin: 0;
  position: absolute;
  text-indent: -9999em;
  border-top: 0.1em solid #a8a8a8;
  border-right: 0.1em solid #a8a8a8;
  border-bottom: 0.1em solid #a8a8a8;
  border-left: ${props =>
    `0.1em solid ${themeGet(props.secondary ? 'colors.secondary' : 'colors.primary')(props)}`};

  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  animation: ${LoadingKeyframes} 0.9s infinite linear;
  animation-fill-mode: forwards;
`

const PlayButtonContainer = styled(Box)`
  ${FlowerCursorActive}
  position: absolute;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-radius: 50%;
  box-sizing: border-box;

  :hover {
    opacity: 0.8;
    background-color: rgba(255, 255, 255, 0.2);
  }
`

interface PlayButtonProps {
  onClick: () => void
  diameter: any
}

export const PlayButton: React.FunctionComponent<PlayButtonProps & any> = props => {
  return (
    <PlayButtonContainer
      width={props.diameter}
      pb={props.diameter}
      onClick={props.onClick}
      {...props}
    />
  )
}
