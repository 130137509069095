import React from 'react'
import { graphql } from 'gatsby'

import { SectionProps } from '@/containers/SectionFactory'
import { ThreeColumnsImageLinksSectionComponent } from '@/components/ThreeColumnsImageLinksSectionComponent'

interface ThreeColumnsImageLinksSectionProps extends SectionProps {
  tiles?: Object
}

const ThreeColumnsImageLinksSection: React.FunctionComponent<
  ThreeColumnsImageLinksSectionProps
> = props => {
  return <ThreeColumnsImageLinksSectionComponent {...props} />
}

export default ThreeColumnsImageLinksSection

export const type = 'DatoCmsThreeColumnsImageLinkSection'
export const query = graphql`
  fragment ThreeColumnsImageLinksSection on DatoCmsThreeColumnsImageLinkSection {
    tiles {
      isImageFullBleed
      linkText
      linkUrl
      linkShouldOpenInNewWindow
      titleText
      backgroundColor {
        hex
      }
      borderColor {
        hex
      }
      imageAsset {
        xs: fluid(imgixParams: { auto: "format,compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
        sm: fluid(imgixParams: { auto: "format,compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
        md: fluid(imgixParams: { auto: "format,compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
        lg: fluid(imgixParams: { auto: "format,compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
        xl: fluid(imgixParams: { auto: "format,compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`
