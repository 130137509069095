import React from 'react'
import { graphql } from 'gatsby'

import { SectionProps } from '@/containers/SectionFactory'
import SingleColumnCenterAligned from '@/components/OneColumnCenterAlignedComponent'

interface SingleColumnCenterAlignedSectionProps extends SectionProps {
  backgroundColor?: Object
  id?: String
  subtitleText: String
  titleText: String
  linkText: String
  linkUrl: String
  drewPickIconStyle?: number
  linkShouldOpenInNewWindow: boolean
  shouldShowDrewPickIcon: boolean
}

const SingleColumnCenterAlignedSection: React.FunctionComponent<SingleColumnCenterAlignedSectionProps> = props => {
  return (
    <SingleColumnCenterAligned {...props}/>
  )
}

export default SingleColumnCenterAlignedSection
 
export const type = 'DatoCmsSingleColumnCenterAlignedSection'

export const query = graphql`
  fragment SingleColumnCenterAligned on DatoCmsSingleColumnCenterAlignedSection {
		backgroundColor {
      hex
    } 
    titleTextUrl
    titleUrlOpenInNewTab
    subtitleText
    titleText
    linkText
    linkUrl
    drewPickIconStyle
    linkShouldOpenInNewWindow
    id
    shouldShowDrewPickIcon
    drewPickIconText
  }
`