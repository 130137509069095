import React from 'react'
import styled from '@emotion/styled'

import theme from '@/style/theme'
import Link from '@/components/Link'
import { Box } from '@/components/layout'
import { Text } from '@/components/text'

interface SubMenuContainerProps {
  ctaText?: String
  textColor?: Object
  ctaUrl?: String
  ctaShouldOpenInNewWindow?: boolean
  isLastChild?: boolean
}

const LinkText = styled(Text)`
  text-decoration: none;
`

const LinkContainer = styled(Box)<any>`
  &:not(:last-child) {
    margin-bottom: ${props => (props.mb ? props.mb / 16 : 'unset')}rem;
  }
`
const SubMenuContainer: React.FunctionComponent<SubMenuContainerProps & any> = props => {
  const { ctaText, textColor, ctaUrl, ctaShouldOpenInNewWindow, isLastChild } = props

  return (
    <LinkContainer mb={!isLastChild ? 19 : 'unset'}>
      <Link
        target={ctaShouldOpenInNewWindow ? '_blank' : '_self'}
        to={ctaUrl}
        title={ctaText}
        onClick={(e: any) => {
          window.scrollTo(0, 0)
        }}
      >
        <LinkText color={textColor ? textColor.hex : '#000'}>{ctaText}</LinkText>
      </Link>
    </LinkContainer>
  )
}

interface FooterSubMenuContainerProps {
  subMenuList?: Array<String>
}

const FooterSubMenuContainer: React.FunctionComponent<
  FooterSubMenuContainerProps & any
> = props => {
  const { subMenuList } = props

  const SubMenuItems = subMenuList.map((SubMenuItem: any, index: any) => {
    return (
      <SubMenuContainer
        isLastChild={subMenuList.length - 1 === index}
        {...SubMenuItem}
        key={SubMenuItem.id}
      />
    )
  })

  return <Box>{SubMenuItems}</Box>
}

const BlockContainer = styled(Box)<any>`
  box-sizing: border-box;
  background-color: ${props => props.bgColor};
  color: ${props => props.TextColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${props => props.py}rem 0;
`
const TitleContainer = styled(Box)<any>`
  box-sizing: border-box;
  flex-basis: 40%;
  text-align: right;
  font-family: ${props =>
    props.fontfamilytype === 'headings' ? theme.fontFaces.headings : theme.fontFaces.body};
`
const MenuContainer = styled(Box)<any>`
  box-sizing: border-box;
  padding-left: ${props => props.pl}rem;
  flex-basis: 60%;
  text-align: left;
  font-family: ${props =>
    props.fontfamilytype === 'body' ? theme.fontFaces.body : theme.fontFaces.body};
`
interface SingleRowProps {
  backgroundColor?: Object
  footerSubMenu?: Array<String>
  title?: String
  titleTextColor?: Object
  SubMenuItems?: Object
}

const SingleRow: React.FunctionComponent<SingleRowProps & any> = props => {
  const { backgroundColor, footerSubMenu, title, titleTextColor } = props

  const footerSubMenus = footerSubMenu.map((footerSubMenuItem: any) => {
    return <FooterSubMenuContainer {...footerSubMenuItem} key={footerSubMenuItem.id} />
  })

  return (
    <BlockContainer bgColor={backgroundColor.hex} TextColor={titleTextColor.hex} py={19 / 16}>
      <TitleContainer fontfamilytype="headings" fontSize={[3, '3.5rem']}>
        <Text>{title}</Text>
      </TitleContainer>
      <MenuContainer fontfamilytype="body" fontSize={[12, 8]} pl={19 / 16}>
        {footerSubMenus}
      </MenuContainer>
    </BlockContainer>
  )
}

const CopyRightsContainer = styled(Box)<any>`
  box-sizing: border-box;
  padding: ${props => `${props.pt}rem ${props.pr}rem ${props.pb}rem ${props.pl}rem`};
  background-color: ${props => props.backgroundColor};
  color: ${props => props.TextColor};
  font-family: ${props =>
    props.fontfamilytype === 'headings' ? theme.fontFaces.headings : theme.fontFaces.body};
`
interface FooterProps {
  copyRightsBackgroundColor?: Object
  copyRightsTextColor?: Object
  copyRightsText?: String
  footerMenuList?: Array<String>
}

interface FooterRowsContainerProps {
  SingleBlock?: Object
}

const FooterRowsContainer: React.FunctionComponent<FooterRowsContainerProps & any> = props => {
  const SingleRowContainer = props.footerMenuList.map((SingleBlock: any) => {
    return <SingleRow {...SingleBlock} key={SingleBlock.id} />
  })

  return <Box>{SingleRowContainer}</Box>
}

const Footer: React.FunctionComponent<FooterProps & any> = props => {
  const { copyRightsTextColor, copyRightsBackgroundColor, copyRightsText, footerMenuList } = props

  return (
    <>
      <FooterRowsContainer footerMenuList={footerMenuList} />
      <CopyRightsContainer
        fontfamilytype="headings"
        fontSize={[9, '1.3rem']}
        color={copyRightsTextColor.hex}
        backgroundColor={copyRightsBackgroundColor.hex}
        pt={13 / 16}
        pr={13 / 16}
        pb={7 / 16}
        pl={13 / 16}
      >
        <Text>{copyRightsText}</Text>
      </CopyRightsContainer>
    </>
  )
}

export default Footer
