import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { FlowerCursorMenu } from '@/components/Cursor'

import { Heading, Text } from '@/components/text'
import Link from '@/components/Link'
import { themeGet } from 'styled-system'
import { Flex, Box } from '@/components/layout'
import ThreeColumnsLinksSection from '@/components/ThreeColumnsLinksSection'
import { DesktopHeader } from '@/components/DesktopHeader'

const HOME_CTA_TEXT = ['H', 'O', 'M', 'E']

const StyledLink = styled(Link)<any>`
  color: ${themeGet('colors.primary')};
  flex: 0;
  display: inline-block;
  text-decoration: none;
`
interface HomeButtonWrapperPorps {
  homeButton?: Array<Object>
  homeButtonTextColor?: Object
}

const HomeButtonWrapperStyled = styled(Flex)`
  align-items: flex-end;
  background-color: ${p => p.theme.colors.black};
  height: 100%;
  ${FlowerCursorMenu}
`
const HomeButtonItem = styled(Box)<any>`
  height: calc(100% - 2px);
  background-color: ${props => props.backgroundColor};
  flex: 1;
  position: relative;
`

const HomeButtonItemText = styled(Text)<any>`
  color: ${props => props.color};
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const HomeButtonWrapper: React.FunctionComponent<HomeButtonWrapperPorps & any> = props => {
  const { homeButton, homeButtonTextColor } = props

  return (
    <HomeButtonWrapperStyled>
      {homeButton.map((item: any, index: any) => {
        return (
          <HomeButtonItem key={item.id} backgroundColor={item.colorItem.hex}>
            <HomeButtonItemText
              fontSize={[10]}
              color={homeButtonTextColor && homeButtonTextColor.hex}
            >
              {HOME_CTA_TEXT[index]}
            </HomeButtonItemText>
          </HomeButtonItem>
        )
      })}
    </HomeButtonWrapperStyled>
  )
}

export interface MenuItemsProps {
  id?: string
  title?: string
  url?: string
  backgroundColor?: object
  textColor?: object
  headingLevel?: number
  siteRelated?: boolean
  multiColoredTextValue?: Object
}

const MenuItemCta = styled(StyledLink)`
  flex: 1;
  text-align: center;
  ${FlowerCursorMenu}
`

export interface MenuItemsContainerProps {
  bgColor?: string
  isopenmenulistitem?: boolean
  toggleMenuList?: Function
  delayLevel?: any
}

const ItemsKeyframes = keyframes`
  0% {
    height: 0%;
  }
  
  100% {
    height: 100%;
  }
`
const ReverseItemsKeyframes = keyframes`
  0% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
`

const MenuItemsContainer = styled(Flex)<MenuItemsContainerProps>`	
  align-items: center;
  overflow: hidden;
  background-color: ${props => props.bgColor};
  ${FlowerCursorMenu}
  height: ${props => (props.isopenmenulistitem ? 0 : '100%')};  
  animation: ${props =>
    props.isopenmenulistitem ? ItemsKeyframes : ReverseItemsKeyframes} ${props =>
  props.isopenmenulistitem ? 0.7 : 0.7}s linear ${props =>
  props.isopenmenulistitem ? props.delayLevel : 0}s forwards;
`
const MenuHeading = styled(Heading)`
  margin-top: ${props => (props.mt ? `${props.mt / 16}rem` : 'unset')};
`

const MenuItems: React.FunctionComponent<MenuItemsProps & any> = props => {
  const {
    siteRelated,
    url,
    backgroundColor,
    textColor,
    headingLevel,
    multiColoredTextValue,
    isopenmenulistitem,
    toggleMenuList,
    delayLevel,
  } = props

  let { title } = props

  title = title.replace(/\((.*?)\)/g, (match: any, content: any) => {
    return `<span class="multi-color-text" color: '#fff'>(${content})</span>`
  })

  return (
    <MenuItemsContainer
      bgColor={backgroundColor.hex}
      delayLevel={delayLevel}
      isopenmenulistitem={isopenmenulistitem}
      ref={a => {
        if (a) {
          if (a.querySelector('.multi-color-text')) {
            a.querySelector('.multi-color-text').style.color =
              multiColoredTextValue && multiColoredTextValue.hex
          }
        }
      }}
    >
      <MenuItemCta
        target={siteRelated ? '_self' : '_blank'}
        to={url}
        title={title}
        onClick={() => {
          toggleMenuList()
        }}
        header={true}
      >
        <MenuHeading level={headingLevel} color={textColor.hex} mt={10}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </MenuHeading>
      </MenuItemCta>
    </MenuItemsContainer>
  )
}

export interface MenuListProps {
  menuList?: Array<Object>
  homeButton?: string
  isopenmenulistitem?: Boolean
}

const MenuItemsKeyframes = keyframes`
  0% {
    flex: 0;    
    height: 0%;
  }
  
  100% {
    flex: 1;    
    height: 100%;
  }
`
const ReverseMenuItemsKeyframes = keyframes`
  0% {
    flex: 1;    
    height: 100%;
  }

  100% {
    flex: 0;
    height: 0%;
  }
`
const MenuListContainer = styled(Flex)<any>`
  flex-direction: column;
  height: 100%;
  animation: ${props => (props.isopenmenulistitem ? MenuItemsKeyframes : ReverseMenuItemsKeyframes)}
    ${props => (props.isopenmenulistitem ? 0.82 : 1.5)}s linear forwards;
  &:hover {
    ${FlowerCursorMenu}
  }
`

const HomeButton = styled(Link)<any>`
  flex: 1;
  display: inline-block;
  height: 100%;
  animation-delay: 1s;
  text-align: center;
  text-decoration: none;
`

const MenuList: React.FunctionComponent<MenuListProps & any> = props => {
  const { homeButton, isopenmenulistitem, toggleMenuList, ...rest } = props

  let { menuList } = props

  menuList =
    menuList &&
    menuList.filter((item: any) => {
      return !item.shouldShowOnDesktop
    })

  return (
    <MenuListContainer {...props}>
      {menuList &&
        menuList.map((listItem: any, index: any) => {
          return listItem.flowerDrewSocialMedia.length === 0 ? (
            <MenuItems
              delayLevel={index * 0.025}
              {...props}
              isopenmenulistitem={isopenmenulistitem}
              key={listItem.id}
              {...listItem}
            />
          ) : (
            <MenuItemsContainer
              delayLevel={index * 0.025}
              isopenmenulistitem={isopenmenulistitem}
              key={listItem.id}
              bgColor={listItem.backgroundColor.hex}
            >
              <ThreeColumnsLinksSection
                header={true}
                titles={listItem.flowerDrewSocialMedia[0].titles}
                isItUniqueStyle={listItem.flowerDrewSocialMedia[0].isItUniqueStyle}
                menu={true}
              />
            </MenuItemsContainer>
          )
        })}
      <MenuItemsContainer
        delayLevel={menuList.length * 0.025}
        isopenmenulistitem={isopenmenulistitem}
      >
        <HomeButton
          to="/"
          onClick={e => {
            if (isopenmenulistitem) toggleMenuList()
          }}
        >
          <HomeButtonWrapper {...props} />
        </HomeButton>
      </MenuItemsContainer>
    </MenuListContainer>
  )
}

export interface MenuCtaProps {
  title?: string
  url?: string
  isSiteRelatedLink?: boolean
  subMenu?: object
}

export interface MenuIconProps {
  toggleMenuList?: Function
  isOpenMenuList?: boolean
}

const SpinnerKeyframes = keyframes`
  0% { transform: rotate(0deg) };
  100% { transform: rotate(360deg) };
`
export interface IconSpinnerProps {
  logoUrl?: string
}

export const IconSpinner = styled.img<IconSpinnerProps>`
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;

  @media (pointer: coarse) {
    animation: ${SpinnerKeyframes} 4s infinite linear;
  }

  &:hover {
    animation: ${SpinnerKeyframes} 4s infinite linear;
  }
`

export const IconSpinnerContainer = styled(Box)`
  width: 3.75rem;
  height: 3.75rem;
  display: inline-block;
  border-radius: 50%;
`

const MenuIcon: React.FunctionComponent<MenuIconProps & any> = props => {
  const { toggleMenuList, isOpenMenuList, logoUrl } = props

  return (
    <IconSpinnerContainer {...props}>
      <StyledLink
        target="_blank"
        to="/#"
        onClick={e => {
          e.preventDefault()
          if (!isOpenMenuList) toggleMenuList()
        }}
      >
        <IconSpinner src={logoUrl} width="100%" height="100%" />
      </StyledLink>
    </IconSpinnerContainer>
  )
}

export interface CloseIconBoxProps {
  toggleMenuList?: Function
  isOpenMenuList?: boolean
  closeIcon?: string
  dotIcon?: string
}

const CloseIconCtaKeyframes = keyframes`
  0% { transform: scale(0,0) rotate(0deg) };
  100% { transform: scale(1,1) rotate(180deg) };
`

const CloseIconKeyframes = keyframes`
  0% { transform: scale(0,0) };
  100% { transform: scale(1,1) };
`

const DotIconKeyframes = keyframes`
  0% { transform: scale(1,1) };
  100% { transform: scale(0,0) };
`
const ReverseCloseIconCtaKeyframes = keyframes`
  0% { transform: scale(1,1) rotate(180deg)  };
  100% { transform: scale(0,0) rotate(0deg) };
`

const ReverseCloseIconKeyframes = keyframes`
  0% { transform: scale(1,1)  };
  100% { transform: scale(0,0) };
`

const ReverseDotIconKeyframes = keyframes`
  0% { transform: scale(0,0) };
  100% { transform: scale(1,1) };
`

const CrossIconCta = styled(StyledLink)`
  animation: ${props =>
      props.isopenmenulistitem ? CloseIconCtaKeyframes : ReverseCloseIconCtaKeyframes}
    0.7s linear forwards;
  position: relative;
`
const CrossIcon = styled.img<any>`
  width: ${32 / 16}rem;
  height: auto;
  animation: ${props => (props.isopenmenulistitem ? CloseIconKeyframes : ReverseCloseIconKeyframes)}
    0.7s linear forwards;
`
const DotIcon = styled.img<any>`
  width: ${32 / 16}rem;
  height: auto;
  animation: ${props => (props.isopenmenulistitem ? DotIconKeyframes : ReverseDotIconKeyframes)}
    0.7s linear forwards;
  position: absolute;
  right: 0;
  top: 0;
`

const CloseIconBox: React.FunctionComponent<CloseIconBoxProps & any> = props => {
  const { toggleMenuList, isOpenMenuList, closeIcon, dotIcon, isopenmenulistitem } = props
  return (
    <Box>
      <CrossIconCta
        target="_blank"
        to="/#"
        isopenmenulistitem={isopenmenulistitem}
        onClick={e => {
          e.preventDefault()
          if (isOpenMenuList) toggleMenuList()
        }}
      >
        <CrossIcon isopenmenulistitem={isopenmenulistitem} src={closeIcon} />
        <DotIcon isopenmenulistitem={isopenmenulistitem} src={dotIcon} />
      </CrossIconCta>
    </Box>
  )
}

export interface HeaderContainerProps {
  menuList?: Array<Object>
  logoUrl?: string
  closeIcon?: string
  homeButton?: string
  toggleMenuList?: Function
  isOpenMenuList?: boolean
  isopenmenulistitem?: boolean
}

const HeaderContainerBox = styled(Box)<any>`
  position: fixed;
  max-width: 1100px;
  margin: 0 auto;
  top: 0;
  right: 0;
  bottom: ${props => (props.isOpenMenuList ? 0 : 'auto')};
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
`
const HeaderIconWrapper = styled(Flex)<any>`
  padding: ${props => `${props.py}rem ${props.pr}rem ${props.py}rem ${props.pl}rem`};
  box-sizing: border-box;
  background-color: ${props => (props.isOpenMenuList ? '#ff8f9c' : 'transparent')};
  justify-content: space-between;
  align-items: center;
`

const HeaderContainer: React.FunctionComponent<HeaderContainerProps & any> = props => {
  const { isOpenMenuList, isopenmenulistitem } = props

  return (
    <HeaderContainerBox
      width="100%"
      height={isOpenMenuList ? window.innerHeight : 'auto'}
      isOpenMenuList={isOpenMenuList}
      isopenmenulistitem={isopenmenulistitem}
      {...props}
    >
      <HeaderIconWrapper
        width={1}
        py={25 / 16}
        pl={30 / 16}
        pr={30 / 16}
        isOpenMenuList={isOpenMenuList}
      >
        <MenuIcon {...props} />
        {isOpenMenuList ? <CloseIconBox {...props} /> : null}
      </HeaderIconWrapper>
      {isOpenMenuList ? <MenuList isopenmenulistitem={isopenmenulistitem} {...props} /> : null}
    </HeaderContainerBox>
  )
}

export interface HeaderProps {
  data?: object
  toggleMenuList?: Function
  isOpenMenuList?: boolean
  isDesktopView?: boolean
}

export const Header: React.FunctionComponent<HeaderProps & any> = props => {
  const { data, isDesktopView } = props
  return (
    <>
      {!isDesktopView ? (
        <HeaderContainer
          data
          menuList={data.menuList}
          logoUrl={data.menuBrandLogo && data.menuBrandLogo.url}
          closeIcon={data.closeIcon && data.closeIcon.url}
          dotIcon={data.dotIcon && data.dotIcon.url}
          homeButton={data.homeButtonColors}
          homeButtonTextColor={data.homeButtonTextColor}
          {...props}
        />
      ) : (
        <DesktopHeader {...props} />
      )}
    </>
  )
}
