import React from 'react'
import { graphql } from 'gatsby'

import { BreakpointKeyedObject } from '@/components/ResponsiveImage'
import { SectionProps } from '@/containers/SectionFactory'
import { HomeHero } from '@/components/HomeHeroSectionComponent'

interface HomeHeroSectionProps extends SectionProps {
  homeBackgroundImage: BreakpointKeyedObject
  brandLogo: BreakpointKeyedObject
  isMenuOpen: boolean
}

export default class HomeHeroSection extends React.Component<HomeHeroSectionProps & any> {
  constructor(props : HomeHeroSectionProps) {
    super(props)
  }

  componentDidMount() {
    // this.handleLogoAnimation()
  }

  componentWillReceiveProps(newProps, oldProps) {
    const {
      isMenuOpen
    } = this.props

    if(newProps !== oldProps && isMenuOpen) {
      clearInterval(this.timeinterval)
    }
  }

  // handleLogoAnimation = () => {
  //   let count = 0
  //   let brandLogoArray = Array.from(document.querySelectorAll('.logo-container'))

  //   if(brandLogoArray) {
  //     brandLogoArray[count].style.zIndex = "1"
  //     brandLogoArray[count].style.opacity = "1"
  //     count++
  //   }

  //   this.timeinterval = setInterval(function() {
  //     for(let i=0; i<brandLogoArray.length; i++) {
  //       brandLogoArray[i].style.zIndex = "0"
  //       brandLogoArray[i].style.opacity = "0"
  //     }

  //     brandLogoArray[count].style.zIndex = "1"
  //     brandLogoArray[count].style.opacity = "1"
      
  //     if(count<brandLogoArray.length-1){
  //       count++
  //     } else {
  //       count=0
  //     }
  //   }, 1000)

  //   window.onscroll = () => {
  //     clearInterval(this.timeinterval)
  //   }
  // }

  render() {
    return (
      <>
        <HomeHero homeBackgroundImage={ this.props.homeBackgroundImage }
          brandLogo={ this.props.brandLogo }
        />
      </>
    )
  }
}

export const type = 'DatoCmsHomeHero'
export const query = graphql`
  fragment HomeHeroSection on DatoCmsHomeHero {
    brandLogo {
      id
      image {
        sm: fluid(imgixParams: { auto: "format,compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
    homeBackgroundImage {
      xs: fluid(imgixParams: { auto: "format,compress" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
      sm: fluid(imgixParams: { auto: "format,compress" }) {
        ...GatsbyDatoCmsFluid_noBase64
      }
    }
  }
`