import React from 'react'
import { graphql } from 'gatsby'

import ArticleSection from '@/components/ArticleSectionComponent'

const ArticleSectionContainer: React.FunctionComponent<any> = props => {
  return (
    <ArticleSection sections={props.article} {...props} />
  )
}

export default ArticleSectionContainer

export const type = 'DatoCmsInlineSingleColumnLeftAligned'
export const query = graphql`
  fragment InlineSingleColumnLeftAligned on DatoCmsInlineSingleColumnLeftAligned {
    id
    title
    titleTextColor {
      hex
    }
    articleBackgroundColor {
      hex
    }
    article {
      ... on DatoCmsTextParagraph {
        __typename
        id
        text
        textType
      }

      ... on DatoCmsInlineImageParagraph {
        __typename
        id
        text
        textType
        rightAlignedImage
        image {
          xs: fluid(imgixParams: { auto: "format,compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
          sm: fluid(imgixParams: { auto: "format,compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }

    footerNotes
    footerNotesColor {
      hex
    }
  }
`