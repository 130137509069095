import React from 'react'
import styled from '@emotion/styled'
import { Box } from '@/components/layout'
import ResponsiveImage, { BreakpointKeyedObject } from '@/components/ResponsiveImage'
import { pick } from 'lodash'

interface BrandLogoProps {
  image: BreakpointKeyedObject
}

const BrandLogoWrap = styled(Box)`
  transition: all 1s ease-in-out;
  margin: 0 auto;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  opacity: 1;
`
const BrandLogo : React.FunctionComponent<BrandLogoProps & any> = (props => {
  const {
    image
  } = props

  return (
    <BrandLogoWrap 
      width={['75%', 1]} 
      className="logo-container"
    >
      <ResponsiveImage fluid={pick(image, 'sm')}  />
    </BrandLogoWrap>
  )
})

interface HomeHeroContainerProps {
  homeBackgroundImage?: Object
}

const HomeHeroContainer = styled(Box)<HomeHeroContainerProps> `
  width: 100%;
  padding-top: 0;
  position: relative;
  background-size: cover;
`
interface HomeHeroProps {
  homeBackgroundImage?: BreakpointKeyedObject
  brandLogo?: Array<Object>
}

export const HomeHero: React.FunctionComponent<HomeHeroProps & any> = props => {
  const {
    homeBackgroundImage,
    brandLogo,
  } = props
  return (
    <HomeHeroContainer>
      <Box 
        ref={a => {
          if(a) {
            //default page load
            a.querySelector('.gatsby-image-wrapper').style.height = '100vh'

            //on resize screen
            if (typeof window !== `undefined`) {
              window.onresize = () => {
                a.querySelector('.gatsby-image-wrapper').style.height = '100vh'
              }
            }
          }
        }}
      >
        <ResponsiveImage fluid={homeBackgroundImage} />
      </Box>
      {/* {brandLogo && brandLogo.map((brandLogoItem:any) => {
        return (
          <BrandLogo key={brandLogoItem.id} {...brandLogoItem} />
        )
      })} */}
      {
        brandLogo &&  <BrandLogo {...brandLogo[0]} />
      }
    </HomeHeroContainer>
  )
}
