import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import {
  fontSize,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  fontFamily,
  color,
  FontWeightProps,
  FontSizeProps,
  TextAlignProps,
  LineHeightProps,
  FontFamilyProps,
} from 'styled-system'

import { withTheme } from 'emotion-theming'

const selectionStyle = p => css`
  background: ${p.theme.colors.transparent};
  color: ${p.theme.colors.textSelection};
`

/**
 * Basic Text
 */
type TextProps = FontWeightProps &
  FontSizeProps &
  TextAlignProps &
  LineHeightProps &
  FontFamilyProps
export const Text = styled('span')<TextProps>`
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${lineHeight};
  ${fontFamily};
  ${color};
  ${letterSpacing};

  &::selection {
    ${selectionStyle};
  }

  * {
    ::selection {
      ${selectionStyle};
    }
  }
`

/**
 * Headings
 */
type HeadingProps = {
  level?: number
}
const HeadingElements: (keyof JSX.IntrinsicElements)[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
const HeadingElement = withTheme((props: any) => {
  const level = Math.min(props.level || 1, 6)
  const H = Text.withComponent(HeadingElements[level - 1])
  return (
    <H
      letterSpacing={props.theme.letterSpacings[level - 1]}
      lineHeight={props.theme.lineHeights[level - 1]}
      fontSize={props.theme.headerSizes[level - 1]}
      {...props}
    />
  )
})

export const Heading = styled(HeadingElement)<HeadingProps>`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 0;

  &::selection {
    ${selectionStyle};
  }
`

/**
 * Error
 */
export const ErrorText = (props: any) => <Text color={'error'} {...props} />
