import React from 'react'
import styled from '@emotion/styled'

import Link from '@/components/Link'
import theme from '@/style/theme'
import { Box } from '@/components/layout'
import { Text } from '@/components/text'

export interface ThreeColumnsLinkSectionProps {
  backgroundColor?: Object
  textShadowColor?: Object
  id?: String
  titles?: Object
  ctaText?: String
  ctaUrl?: String
  ctaShouldOpenInNewWindow?: boolean
  textColor?: Object
  openInNewTab?: boolean
  isItUniqueStyle?: boolean
}

const LinkCTA = styled(Link)<LinkCTAProps>`
  text-align: center;
  font-family: ${props =>
    props.fontfamilytype === 'unique' ? theme.fontFaces.unique : theme.fontFaces.body};
`

interface LinkCTAProps {
  fontfamilytype?: String
}

const LinkBox = styled(Box)`
  flex-basis: 33.33%;
  text-align: center;
`

const LinkContainer = styled(Box)<any>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${props => (props.background ? props.background : 'transparent')};
  flex-wrap: wrap;
  justify-content: space-arround;
`
interface LinkTextProps {
  shadowSpec?: any
  underLine?: boolean
  letterSpacing?: Number
}

const LinkText = styled(Text)<LinkTextProps>`
  text-shadow: ${props => (props.shadowSpec ? props.shadowSpec : 'none')};
  text-decoration: ${props => (props.underLine ? 'underline' : 'none')};
`

const ThreeColumnsLinkSection: React.FunctionComponent<
  ThreeColumnsLinkSectionProps & any
> = props => {
  const { isItUniqueStyle, backgroundColor, menu, textShadowColor, header } = props
  return (
    <>
      {isItUniqueStyle ? (
        <LinkContainer
          background={backgroundColor && backgroundColor.hex}
          px={15}
          py={menu ? 5 : 25}
          {...props}
        >
          {props.titles
            ? props.titles.map((item: any) => (
                <LinkBox key={item.id} my={10}>
                  <LinkCTA
                    target={item.openInNewTab ? '_blank' : '_self'}
                    to={item.ctaUrl}
                    title={item.ctaText}
                    fontfamilytype="unique"
                    header={header ? header : false}
                  >
                    <LinkText
                      letterSpacing={[6, 7]}
                      key={item.id}
                      shadowSpec={`${theme.shadows[2]} ${(textShadowColor && textShadowColor.hex) ||
                        '#B73D89'}`}
                      fontSize={[8, 6, 7, 14]}
                      color={item.textColor.hex}
                    >
                      {item.ctaText}
                    </LinkText>
                  </LinkCTA>
                </LinkBox>
              ))
            : null}
        </LinkContainer>
      ) : (
        <LinkContainer
          background={backgroundColor && backgroundColor.hex}
          px={35}
          py={20}
          {...props}
        >
          {props.titles
            ? props.titles.map((item: any) => (
                <LinkBox key={item.id} my={10}>
                  <LinkCTA
                    target={item.openInNewTab ? '_blank' : '_self'}
                    to={item.ctaUrl}
                    title={item.ctaText}
                  >
                    <LinkText
                      letterSpacing={6}
                      underLine={true}
                      shadowSpec={false}
                      fontSize={['0.8rem', 9, 9, 8, 14]}
                      color={item.textColor.hex}
                    >
                      {item.ctaText}
                    </LinkText>
                  </LinkCTA>
                </LinkBox>
              ))
            : null}
        </LinkContainer>
      )}
    </>
  )
}

export default ThreeColumnsLinkSection
