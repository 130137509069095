import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import SectionFactory, { SectionProps } from '@/containers/SectionFactory'
import { Box, Flex } from '@/components/layout'

import FooterSection from '@/containers/FooterContainer'
import Header from '@/containers/NavSection'

interface PageProps {
  page: object
  header: object
  footer: object
}

const PageFactory: React.FunctionComponent<PageProps & any> = props => {
  const { page, header, footer, menuListStateToggle, isMenuOpen, location } = props

  const sections =
    page.sections &&
    page.sections.map((s: SectionProps, index: number) => (
      <SectionFactory
        isMenuOpen={isMenuOpen && isMenuOpen}
        menuListStateToggle={menuListStateToggle && menuListStateToggle}
        key={index}
        location={location}
        {...s}
      />
    ))

  return (
    <>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Flex
        minHeight={'100vh'}
        flex={1}
        flexDirection={'column'}
        alignItems={'space-between'}
        justifyContent={'space-between'}
      >
        <Header {...header} />
        <Box flex={1}>{sections}</Box>
        <FooterSection {...footer} />
      </Flex>
    </>
  )
}

export default PageFactory

export const allSectionsFragment = graphql`
  fragment allSections on Node {
    ... on DatoCmsHomeHero {
      ...HomeHeroSection
    }

    ... on DatoCmsThreeColumnsLinkSection {
      ...ThreeColumnsLinkSection
    }

    ... on DatoCmsThreeColumnsImageLinkSection {
      ...ThreeColumnsImageLinksSection
    }

    ... on DatoCmsSingleColumnLeftAlignedWithButtonSection {
      ...SingleColumnLeftAlignedWithButtonSection
    }

    ... on DatoCmsFullBleedImageSection {
      ...FullBleedImageSection
    }

    ... on DatoCmsInlineSingleColumnLeftAligned {
      ...InlineSingleColumnLeftAligned
    }

    ... on DatoCmsTwoColumnsImageContentSection {
      ...TwoColumnsImageContentSection
    }

    ... on DatoCmsScrollingTextSection {
      ...ScrollingTextSection
    }

    ... on DatoCmsNewsletterSection {
      ...NewsLetterSection
    }

    ... on DatoCmsFullBleedImageWithShadowBoxSection {
      ...FullBleedImageShadowBoxContainer
    }

    ... on DatoCmsSingleColumnCenterAlignedSection {
      ...SingleColumnCenterAligned
    }

    ... on DatoCmsSingleColumnLeftAlignedSection {
      ...SingleColumnLeftAlignSection
    }

    ... on DatoCmsVideoPlayerSectionModule {
      ...VideoPlayerSectionModule
    }
  }
`
