import React from 'react'
import { graphql } from 'gatsby'
import { SectionProps } from '@/containers/SectionFactory'
import { ScrollingTextSectionComponent } from '@/components/ScrollingTextSectionComponent'


interface ScrollingTextSectionProps extends SectionProps {
  titleText?: string
  titleTextUrl?: string
  titleColor?: object
  backgroundColor?: object
  shouldShowShadowOnTitle?: boolean
  shouldScrollFromRightToLeft?: boolean
  scrollSpeedLevel?: number,
}

const ScrollingTextSection: React.SFC<ScrollingTextSectionProps> = props => {
  return (
    <>
      <ScrollingTextSectionComponent {...props}/>
    </>
  )
}

export default ScrollingTextSection 

export const type = 'DatoCmsScrollingTextSection'
export const query = graphql`
  fragment ScrollingTextSection on DatoCmsScrollingTextSection {
    titleText
    titleTextUrl
    titleColor {
      hex
    }
    backgroundColor {
      hex
    }
    shouldShowShadowOnTitle
    shouldScrollFromRightToLeft
    scrollSpeedLevel
  }
`