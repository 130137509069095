import React from 'react'
import styled from '@emotion/styled'
import { Heading, Text } from '@/components/text'
import ShadowButton from '@/components/ShadowButton'
import { Box } from '@/components/layout'

const ModuleContainer = styled(Box)<any>`
  background-color: ${props => props.backgroundColor};
  box-sizing: border-box;
  padding: ${props => `${props.padding}rem`};
`

const TitleHeadingComponent = styled(Heading)<any>`
  margin-top: ${props => `${props.m}rem`};
  line-height: unset;
  margin-bottom: 0;
`

const SubTitleHeadingComponent = styled(Heading)<any>`
  margin-top: ${props => `${props.m}rem`};
  line-height: unset;
  margin-bottom: 0;
`

const Content = styled(Text)<any>`
  display: block;
  margin-top: ${props => `${props.m}rem`};
`

const Separator = styled.span<any>`
  height: ${props => `${props.height}px`};
  margin-top: 0;
  display: block;
`

const ShadowButtonHeading = styled(Heading)`
  margin-top: ${5 / 16}rem;
`

interface SingleColumnLeftAlignedButtonProps {
  subtitleText?: string
  subtitleType?: string
  subtitleColor?: object
  titleText?: string
  titleType?: string
  titleColor?: object
  backgroundColor?: object
  copyText?: string
  copyColor?: object
  separatorHeight?: number
  ctaText?: string
  ctaUrl?: string
  ctaShouldOpenInNewWindow?: boolean
  ctaShadowColor?: object
  ctaBackgroundColor?: object
  ctaTextColor?: object
  subtitleTypeLevel?: any
  titleTypeLevel?: any
  titleSpacing?: any
  subTitleSpacing?: any
}

export const SingleColumnLeftAlignedButton: React.FunctionComponent<
  SingleColumnLeftAlignedButtonProps & any
> = props => {
  const {
    subtitleText,
    subtitleType,
    subtitleColor,
    titleText,
    titleType,
    titleColor,
    backgroundColor,
    copyText,
    copyColor,
    separatorHeight,
    ctaText,
    ctaUrl,
    ctaShadowColor,
    ctaBackgroundColor,
    ctaTextColor,
    ctaShouldOpenInNewWindow,
    subtitleTypeLevel,
    titleTypeLevel,
    titleSpacing,
    subTitleSpacing,
    ...rest
  } = props

  return (
    <ModuleContainer width={1} padding={25 / 16} backgroundColor={backgroundColor.hex}>
      <SubTitleHeadingComponent
        m={subTitleSpacing}
        color={subtitleColor && subtitleColor.hex}
        level={subtitleTypeLevel}
      >
        {subtitleText}
      </SubTitleHeadingComponent>

      <TitleHeadingComponent
        m={titleSpacing}
        color={titleColor && titleColor.hex}
        level={titleTypeLevel}
      >
        {titleText}
      </TitleHeadingComponent>

      <Content
        m={25 / 16}
        fontSize={[18, 17]}
        lineHeight={[13, 14]}
        letterSpacing={[13, 12]}
        color={copyColor && copyColor.hex}
      >
        {copyText}
      </Content>

      <Separator height={separatorHeight} />

      <ShadowButton
        mt={25 / 16}
        to={ctaUrl}
        backgroundColor={ctaBackgroundColor.hex}
        shadowColor={ctaShadowColor.hex}
        ctaShouldOpenInNewWindow={ctaShouldOpenInNewWindow}
      >
        <ShadowButtonHeading level={4} color={ctaTextColor && ctaTextColor.hex}>
          {ctaText}
        </ShadowButtonHeading>
      </ShadowButton>
    </ModuleContainer>
  )
}
