import styled from '@emotion/styled'
import {
  space,
  width,
  fontSize,
  color,
  SpaceProps,
  WidthProps,
  FontSizeProps,
  alignItems,
  alignContent,
  justifyContent,
  flexWrap,
  flexBasis,
  flexDirection,
  flex,
  justifySelf,
  alignSelf,
  AlignItemsProps,
  AlignContentProps,
  JustifyContentProps,
  FlexWrapProps,
  FlexBasisProps,
  FlexDirectionProps,
  FlexProps,
  BgColorProps,
  ColorStyleProps,
  JustifySelfProps,
  AlignSelfProps,
  height,
  HeightProps,
  display,
  DisplayProps,
  MaxHeightProps,
  maxHeight,
  MinHeightProps,
  minHeight,
  MaxWidthProps,
  maxWidth,
  OrderProps,
  order,
  overflow,
  OverflowProps,
  position,
  PositionProps,
  BottomProps,
  bottom,
  left,
  LeftProps
} from 'styled-system'

export type BoxProps = SpaceProps &
  HeightProps &
  WidthProps &
  FontSizeProps &
  BgColorProps &
  ColorStyleProps &
  FlexProps &
  JustifySelfProps &
  AlignSelfProps &
  DisplayProps &
  MaxHeightProps &
  MinHeightProps &
  MaxWidthProps &
  OrderProps &
  OverflowProps &
  PositionProps & 
  BottomProps & 
  LeftProps
export const Box = styled('div') <BoxProps>`
  ${space}
  ${height}
  ${width}
  ${fontSize}
  ${color}
  ${flex}
  ${justifySelf}
  ${alignSelf}
  ${display}
  ${maxHeight}
  ${minHeight}
  ${maxWidth}
  ${order}
  ${overflow}
  ${position}
  ${bottom}
  ${left}
`

export type FlexBoxProps = AlignItemsProps &
  AlignContentProps &
  JustifyContentProps &
  FlexWrapProps &
  FlexBasisProps &
  FlexDirectionProps
export const Flex = styled(Box) <FlexBoxProps>`
  ${alignItems}
  ${alignContent}
  ${justifyContent}
  ${flexWrap}
  ${flexBasis}
  ${flexDirection}
  display: flex;
`
