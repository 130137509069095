import React from 'react'
import { graphql } from 'gatsby'

import { SectionProps } from '@/containers/SectionFactory'
import ThreeColumnsLinkSection from '@/components/ThreeColumnsLinksSection'

interface ThreeColumnsLinkSectionContainerProps extends SectionProps {
  titles?: Object
	isItUniqueStyle?: boolean
}


const ThreeColumnsLinkSectionContainer: React.FunctionComponent<ThreeColumnsLinkSectionContainerProps> = props => {
  return (
    <ThreeColumnsLinkSection {...props}/>
  )
}

export default ThreeColumnsLinkSectionContainer
 
export const type = 'DatoCmsThreeColumnsLinkSection'
export const query = graphql`
  fragment ThreeColumnsLinkSection on DatoCmsThreeColumnsLinkSection {
    isItUniqueStyle
    backgroundColor {
      hex
    }
    textShadowColor {
      hex
    }
    titles {
        id
        ctaText
        ctaUrl
        textColor {
          hex
        }
        desktopTextColor {
          hex
        }
        desktopBackgroundColor {
          hex
        }
        openInNewTab
      }
  }
`