import React from 'react'
import styled from '@emotion/styled'
import { compose, withHandlers } from 'recompose'
import { fontSize, themeGet, space } from 'styled-system'

import { FlowerCursorActive } from '@/components/Cursor'
import Link from '@/components/Link'
import { Flex, Box } from '@/components/layout'

export interface ShadowButtonProps {
  to?: string
  backgroundColor?: string
  shadowColor?: string
  ctaShouldOpenInNewWindow?: boolean
}

interface ButtonContentProps {
  children: any
}

const ButtonContent = styled(Flex)<ButtonContentProps>`
`

const StyledLink = styled(Link)`
  color: ${themeGet('colors.primary')};
  flex: 0;
  display: inline-block;
  text-decoration: none;
`

export const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${FlowerCursorActive}
  max-width: 100%;
  padding-top: 0.6em;
  padding-bottom: 0.55em;
  transform: scale(1);
  ${fontSize};
  ${space};

  ${p => ({
    fontWeight: p.theme.fontWeights.bold,
    backgroundColor: p.backgroundColor ? p.backgroundColor : '#EFEDE2',
    color: p.secondary ? p.theme.colors.secondary : p.theme.colors.primary,
    border: `2px solid ${p.backgroundColor ? p.backgroundColor : 'EFEDE2'} `,
    boxShadow: `0.187rem 0.187rem ${p.shadowColor ? p.shadowColor : '#FF92FF'}`
  })};

  &:active {
    transition: transform 200ms ease 0s;
    transform: ${p => (p.isLoading || p.disabled ? `scale(1)` : `scale(0.95)`)};
  }

  &:disabled {
    background-color: blue;
  }

  transition: transform 500ms ease-in-out, opacity 300ms ease-in-out,
    scale 0ms 300ms border 200ms 100ms;
`

const ShadowButtonContainer = styled(Box)`
  margin-top: ${porps => `${porps.mt}rem`};
  display: inline-block;
`

const enhance = compose(
  withHandlers({
    handleClick: (p: any) => (event: Event) => {
      const { handleClick } = p
      if (handleClick) {
        event.preventDefault()
        event.stopPropagation()
        handleClick()
      }
    },
  })
)

const ShadowButton: React.FunctionComponent<ShadowButtonProps & any> = enhance(props => {
  const {
    children,
    handleClick,
    to,
    backgroundColor,
    shadowColor,
    ctaShouldOpenInNewWindow,
    ...rest
  } = props

  if (to) {
    return (
      <ShadowButtonContainer {...props}>
        <StyledLink
          target=""
          to={to}
          onClick={ (e: Event) => {
            if (handleClick) {
              e.stopPropagation()
            }

            if (handleClick && !to) {
              e.preventDefault()
            }
          }}
        >
          <ButtonContainer
            px={[3, 4]}
            py={[1, 2]}
            backgroundColor={backgroundColor}
            shadowColor={shadowColor}
          >
            <ButtonContent
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              {...rest}
            >
              {children}
            </ButtonContent>

            
          </ButtonContainer>
        </StyledLink>
      </ShadowButtonContainer>
    )
  } else {
    return (
      <ShadowButtonContainer {...props}>
        <ButtonContainer
          onClick={handleClick}
          px={['1em', '3em']}
        >
          <ButtonContent
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {children}
          </ButtonContent>
        </ButtonContainer>
      </ShadowButtonContainer>
    )
  }
})

export default ShadowButton