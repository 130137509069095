import React from 'react'
import styled from '@emotion/styled'
import { get, isNil, isEmpty } from 'lodash'

import ResponsiveImage, { BreakpointKeyedObject } from '@/components/ResponsiveImage'
import { Text } from '@/components/text'
import Link from '@/components/Link'
import { Flex, Box } from '@/components/layout'

interface TileContainerProps {
  backgroundColor?: object
  borderColor?: object
  imageAsset?: BreakpointKeyedObject
  isImageFullBleed?: boolean
  linkShouldOpenInNewWindow?: boolean
  linkText?: string
  linkUrl?: string
  titleText?: string
}

const BorderColoredFullBleedImage = styled(ResponsiveImage)<any>`
  display: block;
  padding: 25px;
  background-color: ${props => props.borderColor};
  & picture {
    height: calc(100% - 50px);
    width: calc(100% - 50px);
    top: 25px;
    left: 25px;
    position: absolute;
  }
`

const NoBorderFullBleedImage = styled(ResponsiveImage)<any>`
  display: block;
  & picture {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
`

const StyledLink = styled(Link)<any>`
  color: #000;
  flex: 0;
  display: block;
  font-size: 1rem;
  text-align: center;
`

const CopyText = styled(Text)`
  display: block;
  text-align: center;
  font-weight: 600;
`

const TileContainerStyled = styled(Flex)<any>`
  flex-basis: 33.33%;
  box-sizing: border-box;
  flex-direction: column;
  background-color: ${props => props.backgroundColor};
  padding: ${props => `${props.py}rem ${props.px}rem`};

  .gatsby-image-wrapper {
    height: ${props => props.isImageFullBleed ? '100%' : 'auto'};
  }
`

const TitleContentContainer = styled(Box)<any>`
  text-align: center;
  margin-top: ${props => props.mt}rem;
`

const ImageContainer = styled(Box)`
  flex: 1;
`

const ImageWrapper = styled(Box)`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`

interface StyledTextOnlyProps {
  titleText: String,
  linkText: String
}

const StyledTextOnly: React.FunctionComponent<StyledTextOnlyProps> = ({ linkText, titleText }) => {

  if (isEmpty(linkText) && isEmpty(titleText)) {
    return null
  }

  return (
  <TitleContentContainer mt={30 / 16}>
    <StyledLink>
      <Text color={'#000'} fontSize={['0.9rem', 14]} lineHeight={[9]} letterSpacing={[9]}>
        {linkText}
      </Text>
    </StyledLink>
    <CopyText fontSize={['0.9rem', 14]} lineHeight={[9]} letterSpacing={[9]}>
      {titleText}
    </CopyText>
  </TitleContentContainer>
  )
}


interface FullBleedImageProps {
  imageAsset: Object,
  borderColor: Object
}

const FullBleedImage: React.FunctionComponent<FullBleedImageProps> = props => {
  const {
    imageAsset,
    borderColor: border
  } = props

  const borderColor = get(border, 'hex')

  if (isNil(borderColor)) {
    return  <NoBorderFullBleedImage fluid={imageAsset} />
  }

  return (
    <BorderColoredFullBleedImage fluid={imageAsset} borderColor={borderColor} />
  )
}

const TileContainer: React.FunctionComponent<TileContainerProps & any> = props => {
  const {
    backgroundColor,
    borderColor,
    imageAsset,
    isImageFullBleed,
    linkShouldOpenInNewWindow,
    linkText,
    linkUrl,
    titleText,
  } = props

  const boxPaddingLevel = isImageFullBleed ? 0 : linkText && linkText.trim() !== '' ? 1 : 2

  const imageWithPadding =
    linkText && linkText.trim() !== '' && linkUrl && linkUrl.trim() !== '' ? false : true

  return (
    <TileContainerStyled
      backgroundColor={backgroundColor && backgroundColor.hex}
      py={boxPaddingLevel === 1 ? 30 / 16 : boxPaddingLevel === 2 ? 15 / 16 : 0}
      px={boxPaddingLevel === 1 ? 20 / 16 : boxPaddingLevel === 2 ? 15 / 16 : 0}
      isImageFullBleed={isImageFullBleed}
    >
      {isImageFullBleed ? (
        <FullBleedImage imageAsset={imageAsset} borderColor={borderColor} />
      ) : (
        <ImageContainer>
          <ImageWrapper>
            <ResponsiveImage fluid={imageAsset} />
          </ImageWrapper>
        </ImageContainer>
      )}
      {!isImageFullBleed && !imageWithPadding ? (
        <TitleContentContainer mt={30 / 16}>
          <StyledLink to={linkUrl} target={linkShouldOpenInNewWindow ? '_blank' : ''}>
            <Text color={'#000'} fontSize={['0.9rem', 14]} lineHeight={[9]} letterSpacing={[9]}>
              {linkText}
            </Text>
          </StyledLink>
          <CopyText fontSize={['0.9rem', 14]} lineHeight={[9]} letterSpacing={[9]}>
            {titleText}
          </CopyText>
        </TitleContentContainer>
      ) :
        <StyledTextOnly titleText={titleText} linkText={linkText} />
      }
    </TileContainerStyled>
  )
}

interface ThreeColumnsImageLinksSectionComponentProps {
  tiles?: Array<Object>
}

export const ThreeColumnsImageLinksSectionComponent: React.FunctionComponent<
  ThreeColumnsImageLinksSectionComponentProps & any
> = props => {
  const TileComponents = props.tiles.map((tileData: any, index: Number) => {
    return <TileContainer {...tileData} key={index} />
  })

  return <Flex>{TileComponents}</Flex>
}
