import React from 'react'
import styled from '@emotion/styled'

import { Heading, Text } from '@/components/text'
import ResponsiveImage, { BreakpointKeyedObject } from '@/components/ResponsiveImage'
import { SectionProps } from '@/containers/SectionFactory'
import ShadowButton from '@/components/ShadowButton'
import Link from '@/components/Link'

import { Flex, Box } from '@/components/layout'

interface ContentTileProps extends SectionProps {
  title: string
  titleType: string
  titleColor: object
  backgroundColor?: object
  copyText: string
  copyColor: object
  cta: string
  isAltFontSize: boolean
  isAltImageRatio: boolean
  ctaBackgroundColor?: object
  ctaShadowColor?: object
  ctaTextColor?: object
  ctaShouldOpenInNewWindow?: boolean
}

const ContentTileContainer = styled(Flex)`
  padding: ${props => `${props.py}rem ${props.px}rem ${props.pb}rem`};
  flex-direction: column;
  justify-content: space-between;
`

const ContentTileHeading = styled(Heading)`
  margin: 0;
  word-break: normal;
`

const CopyContent = styled(Text)<any>`
  display: block;
  margin-top: ${props => props.mt}rem;
`

const ContentContainer = styled(Flex)<any>`
  background-color: ${props => props.backgroundColor};
  position: relative;
`

const ShadowButtonStyled = styled(Box)<any>`
  margin-top: ${15 / 16}rem;
  text-decoration: underline;
`

const StyledLink = styled(Link)<any>`
  color: ${props => props.color};
  text-decoration: underline;
  flex: 0;
  display: inline-block;
`

const ShadowButtonHeading = styled(Heading)`
  margin-top: ${5 / 16}rem;
`

const ContentTile: React.FunctionComponent<ContentTileProps & any> = (props, index) => {
  const {
    title,
    copyText,
    isAltFontSize,
    isAltImageRatio,
    titleColor,
    ctaUrl,
    copyColor,
    backgroundColor,
    cta,
    ctaBackgroundColor,
    ctaShadowColor,
    titleType,
    ctaTextColor,
    ctaShouldOpenInNewWindow,
  } = props

  const titleLevel = titleType ? parseInt(titleType.replace(/\D/g, '')) : 0

  return (
    <ContentContainer
      flex={isAltImageRatio ? 0.68 : 1}
      backgroundColor={backgroundColor.hex}
      key={index}
    >
      <ContentTileContainer
        px={isAltFontSize || isAltImageRatio ? 15 / 16 : 25 / 16}
        py={isAltFontSize || isAltImageRatio ? 30 / 16 : 40 / 16}
        pb={isAltFontSize || isAltImageRatio ? 30 / 16 : 40 / 16}
      >
        <Box>
          <ContentTileHeading color={titleColor.hex} level={titleLevel} lineHeight={[3, 0]}>
            {title}
          </ContentTileHeading>

          <CopyContent color={copyColor.hex} mt={30 / 16} fontSize={[12, 8]}>
            {copyText}
          </CopyContent>
        </Box>
        <ShadowButtonStyled pb={25 / 16} pl={15 / 16} className="shadow-button-styled">
          {isAltFontSize ? (
            <StyledLink
              color={ctaTextColor && ctaTextColor.hex}
              target={ctaShouldOpenInNewWindow ? '_blank' : ''}
              to={ctaUrl}
            >
              <Text color={ctaTextColor && ctaTextColor.hex}>{cta}</Text>
            </StyledLink>
          ) : (
            <ShadowButton
              to={ctaUrl}
              backgroundColor={ctaBackgroundColor.hex}
              shadowColor={ctaShadowColor.hex}
              ctaShouldOpenInNewWindow={ctaShouldOpenInNewWindow}
            >
              <ShadowButtonHeading
                level={4}
                fontSize={[16, 15]}
                lineHeight={[11, 10]}
                letterSpacing={[11, 10]}
                color={ctaTextColor && ctaTextColor.hex}
              >
                {cta}
              </ShadowButtonHeading>
            </ShadowButton>
          )}
        </ShadowButtonStyled>
      </ContentTileContainer>
    </ContentContainer>
  )
}

interface ImageTileProps extends SectionProps {
  imageAsset: BreakpointKeyedObject
}

const ImageTile: React.FunctionComponent<ImageTileProps & any> = (props, index) => {
  const { imageAsset } = props
  return (
    <Flex flex={1} key={index}>
      <Box
        width={1}
        ref={a => {
          if (a) {
            a.querySelector('.gatsby-image-wrapper').style.height = '100%'
          }
        }}
      >
        <ResponsiveImage fluid={imageAsset} />
      </Box>
    </Flex>
  )
}

interface TileComponentsProps {
  tiles?: Array<object>
}

const TileComponents: React.FunctionComponent<TileComponentsProps & any> = props => {
  const { tiles } = props

  if (!tiles) {
    return null
  }

  return tiles.map((tileData: any, index: number) => {
    const typename = tileData.__typename

    if (typename == 'DatoCmsContentTile') {
      return ContentTile(tileData, index)
    } else if (typename == 'DatoCmsImageTile') {
      return ImageTile(tileData, index)
    }

    return null
  })
}

interface TwoColumnImageContentProps {}

export const TwoColumnImageContent: React.FunctionComponent<
  TwoColumnImageContentProps & any
> = props => {
  return (
    <Flex>
      <TileComponents tiles={props.tiles} />
    </Flex>
  )
}
