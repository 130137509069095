import React from 'react'
import { graphql } from 'gatsby'

import FormatText from '@/utils/FormatText'
import SingleColumnLeftAlignSection from '@/components/SingleColumnLeftAlignSection'

const SingleColumnLeftAlignSectionContainer: React.FunctionComponent<any> = props => {
  const formatCopyText = props.copyText && FormatText(props.copyText)
  const formattedCopyText = () => {
    return { __html: formatCopyText }
  }
  const headingLevel = props.titleType && parseInt(props.titleType.replace(/\D/g,''))
  return (
    <SingleColumnLeftAlignSection 
      headingLevel={headingLevel} 
      formatCopyText={formattedCopyText()} 
      {...props}
    />
  )
}

export default SingleColumnLeftAlignSectionContainer
export const type = 'DatoCmsSingleColumnLeftAlignedSection'
export const query = graphql`
  fragment SingleColumnLeftAlignSection on DatoCmsSingleColumnLeftAlignedSection {
    backgroundColor {
      hex
    }
    subtitleText
    subtitleType
    subtitleColor {
      hex
    }
    titleText
    titleType
    titleColor {
      hex
    }
    copyText
    copyColor {
      hex
    }
    copyType
    numberDecorator
    numberDecoratorColor {
      hex
    }
    linkUrl
    linkTitle
    linkColor {
      hex
    }
    linkOpenInNewTab
    subLinkTitle 
    subLinkUrl
    subLinkColor {
      hex
    }
    subLinkOpenInNewTab
    bottomText
    bottomTextColor {
      hex
    }
    bottomLinkTitle
    bottomLinkColor {
      hex
    }
    bottomLinkUrl
    bottomLinkOpenInNewTab
  }
`

