import React from 'react'
import styled from '@emotion/styled'
import theme from '@/style/theme'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { Box } from '@/components/layout'
import { FlowerCursorMenu, FlowerCursorActive } from '@/components/Cursor'

interface OutboundLinkProps {
  to?: string
  title?: string
  header?: boolean
  grow?: boolean
  children: Object
}

const StyledOutboundLink = styled(OutboundLink)<OutboundLinkProps>`
  color: ${p => p.theme.colors.black};
  text-align: center;
  ${props => (props.header ? FlowerCursorMenu : FlowerCursorActive)}
`

const GrownStyledBox = styled(Box)<any>`
  align-items: center;
  height: 100%;
  justify-content: center;
`

const GrownStyledOutboundLink = styled(OutboundLink)<OutboundLinkProps>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  ${FlowerCursorMenu}
`

export default (props: OutboundLinkProps) => {
  const { to, title, header, grow } = props

  if (grow) {
    return (
      <GrownStyledBox width={1}>
        <GrownStyledOutboundLink
          header={header ? header : false}
          target="_blank"
          href={to}
          title={title}
        >
          {props.children}
        </GrownStyledOutboundLink>
      </GrownStyledBox>
    )
  }

  return (
    <Box width={1}>
      <StyledOutboundLink
        header={header ? header : undefined}
        target="_blank"
        href={to}
        title={title}
      >
        {props.children}
      </StyledOutboundLink>
    </Box>
  )
}
