import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import Link from '@/components/Link'
import theme from '@/style/theme'
import { Heading } from '@/components/text'
import { Box } from '@/components/layout'


const ScrollingTextContainer = styled(Box)<any>`
  padding: ${props => props.padding}rem 0;
  background-color: ${props => props.backgroundColor};
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
`
const ScrollingTextRightLeftAnimation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
`

const ScrollingTextLeftRightAnimation = keyframes`
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
`

const ScrollingTextContent = styled(Heading)<any>`
  margin: 0;
  line-height: unset;
  display: inline-block;
  padding-left: ${props => props.isLink ? 0 : 100}%;
  text-shadow: ${props => props.textShadow || false};
  animation: ${ props => !props.isLink ? props.shouldScrollFromRightToLeft ? ScrollingTextRightLeftAnimation :  ScrollingTextLeftRightAnimation : '' } ${props => props.scrollSpeedLevel || 10}s linear infinite;
`
const ScrollTextWrapper = styled(Link)`
  margin: 0;
  line-height: unset;
  display: inline-block;
  padding-left: 100%;
  text-shadow: ${props => props.textShadow || false};
  animation: ${ props => props.shouldScrollFromRightToLeft ? ScrollingTextRightLeftAnimation :  ScrollingTextLeftRightAnimation } ${props => props.scrollSpeedLevel || 10}s linear infinite;
`
interface ScrollingTextSectionComponentProps {
  titleText?: string
  titleColor?: object
  backgroundColor?: object
  shouldShowShadowOnTitle?: boolean
  shouldScrollFromRightToLeft?: boolean
  scrollSpeedLevel?: number,
  titleTextUrl?: string
}

export const ScrollingTextSectionComponent: React.FunctionComponent<ScrollingTextSectionComponentProps & any> = props => {
  const { 
    titleText,
    titleColor,
    backgroundColor,
    shouldShowShadowOnTitle,
    shouldScrollFromRightToLeft,
    scrollSpeedLevel,
    titleTextUrl,
     ...rest 
    } = props

  return (
    <ScrollingTextContainer 
      width={1} 
      padding={1} 
      backgroundColor={backgroundColor.hex}
    >
    {
      titleTextUrl && titleTextUrl.trim() !== '' ? 
        <ScrollTextWrapper 
          to={titleTextUrl}
          shouldScrollFromRightToLeft= {shouldScrollFromRightToLeft}  
        >
          <ScrollingTextContent
            level={2}
            isLink={true}
            textShadow={shouldShowShadowOnTitle ? `${theme.shadows[2]} #81C5CE` : false}
            shouldScrollFromRightToLeft= {shouldScrollFromRightToLeft}
            color={titleColor.hex}
            scrollSpeedLevel={10}
          >
          {titleText}
          </ScrollingTextContent>
        </ScrollTextWrapper>
      :
        <ScrollingTextContent
          level={2} 
          textShadow={shouldShowShadowOnTitle ? `${theme.shadows[2]} #81C5CE` : false}
          shouldScrollFromRightToLeft= {shouldScrollFromRightToLeft}
          color={titleColor.hex}
          scrollSpeedLevel={10}
        >
          {titleText}
        </ScrollingTextContent>
    }
    </ScrollingTextContainer>
  )
}