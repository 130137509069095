import React from 'react'

import MediaQuery from 'react-responsive'
import Image from 'gatsby-image'
import { withTheme } from 'emotion-theming'
import { keys } from 'lodash'

/**
 * Documentation:
 * https://www.notion.so/mattersupply/ResponsiveImage-1ce81810b6944d5abddf03cdcd33451c
 */

interface BreakpointsProvider {
  breakpoints: string[]
  breakpointsMap: string[]
  mq: string[]
}

export interface BreakpointKeyedObject {
  [index: string]: any
}

interface ResponsiveImageProps {
  fluid?: BreakpointKeyedObject
  fixed?: BreakpointKeyedObject
  theme: BreakpointsProvider
}

const ResponsiveImage: React.SFC<ResponsiveImageProps> = props => {
  const { fluid = {}, fixed = {}, theme, ...rest } = props as any
  const { breakpoints, breakpointsMap, mq } = theme

  let previousBp: string | null = null
  const mediaQueryConfigurations = breakpointsMap.reduce(
    (acc: BreakpointKeyedObject, bp: string, index: number) => {
      const image = fluid[bp] || fixed[bp]
      if (image) {
        let query = {
          base: '',
          max: null,
        }

        // If we don't have a previous media query, start at the lowest.
        // Otherwise, start with the current and update the max of the previous.
        if (!previousBp) {
          query.base = mq[breakpointsMap[0]]
        } else {
          query.base = mq[bp]
          acc[previousBp].max = breakpoints[index - 1]
        }

        acc[bp] = query
        previousBp = bp
      }
      return acc
    },
    {}
  )

  const mediaQueries = keys(mediaQueryConfigurations).reduce((acc, bp) => {
    const c = mediaQueryConfigurations[bp]
    acc[bp] = c.base + (c.max ? ` and (max-width: ${c.max})` : '')
    return acc
  }, {})

  return (
    <>
      {keys(mediaQueries).map(bp => (
        <MediaQuery key={`${bp}`} query={mediaQueries[bp]}>
          <Image fluid={fluid['sm']} {...rest} />
        </MediaQuery>
      ))}
    </>
  )
}

export default withTheme(ResponsiveImage)
