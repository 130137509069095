import React from 'react'
import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'
import OutboundLink from '@/components/Outboundlink'

const CustomLink = ({ children, to, activeClassName, grow, ...other }) => {
  const internal = /^\/(?!\/)/.test(to)
  if (internal) {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
        {children}
      </GatsbyLink>
    )
  } else if (grow) {
    return (
      <OutboundLink to={to} grow={grow} {...other}>
        {children}
      </OutboundLink>
    )
  } else {
    return (
      <OutboundLink to={to} {...other}>
        {children}
      </OutboundLink>
    )
  }
}

const Link = styled(CustomLink)`
  display: inline-block;
  text-decoration: none;
`

export default Link
